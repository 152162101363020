import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {NewsParticular} from "../../news/models/news";

@Injectable({
  providedIn: 'root'
})
export class SettingsServiceService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  //get application settings
  getAppSettings () {
    return this.http.get<any>(this.apiUrl + 'app/settings');
  }


  // save a news, with bar(s) & ticker(s)
  saveNewsParticulars (particulars: NewsParticular[]) {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});

    const post_str = JSON.parse(JSON.stringify(particulars));

    return this.http.put<any>(this.apiUrl + 'news/particular/save', post_str, { headers: headers });
  }
}
