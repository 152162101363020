import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Action } from '@ngrx/store';

import * as UserActions from './user.actions';
import { UserState } from '../../store/app.state';
import { User, Profile } from '../../shared/models/user';
import { NewsState } from './../../store/app.state';

const myuser: UserState = { user: { email: null, name: null, token: {token: null}, roles: null, roles_a: null, profile: new Profile() } };
export const initialState: UserState = myuser;

export type Actions = UserActions.All;
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export function reducer(state = initialState, action: ActionWithPayload<any>): UserState {
  switch (action.type) {
    case UserActions.USER_LOGIN: {
      return {
        ...state
      };
    }

    case UserActions.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case UserActions.USER_GET_SETTINGS: {
      return {
        ...state
      };
    }

    case UserActions.USER_GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case UserActions.USER_LOGOUT: {
      // return {
      //   ...state
      // };
      return initialState;
    }

    case UserActions.USER_LOGOUT_SUCCESS: {
      // return {
      //   // ...state,
      //   // user: action.payload,
      //   user: { email: null, name: null, token: {token: null}, roles: null, roles_a: null }
      // };
      return initialState;
    }




    case UserActions.SERVER_USER_GET_SETTINGS: {
      return {
        ...state
      };
    }

    case UserActions.SERVER_USER_GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getUserState = createFeatureSelector<UserState>('userState');

export const getUser = createSelector(
  getUserState,
  (state: UserState) => state.user
);
