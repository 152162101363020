import { Routes, CanActivate } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './shared/services/auth.guard';

export const AppRoutes: Routes = [{
  path: '',
  component: FullComponent,
  children: [
    {
      path: 'admin',
      loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      // canActivate: [AuthGuard],
      // data: { isPublic: false, role: 'editor' }
  },{
    path: '',
    redirectTo: '/user',
    pathMatch: 'full'
  },{
    path: 'user',
    loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule),
    canActivate: [AuthGuard],
    data: { isPublic: true, role: 'user' }
  },
  {
    path: 'editor',
    loadChildren: () => import('./editor/editor.module').then(m => m.EditorModule),
    // canActivate: [AuthGuard],
    // data: { isPublic: false, role: 'editor' }
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canActivate: [AuthGuard],
    data: { isPublic: false, role: 'journalist' }
  },
  {
    path: 'control',
    loadChildren: () => import('./control/control.module').then(m => m.ControlModule),
    canActivate: [AuthGuard],
    data: { isPublic: false, role: 'journalist' }
  },
  {
    path: 'material',
    loadChildren: () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('./datatables/datatables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'widgets',
    loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('./charts/chartslib.module').then(m => m.ChartslibModule)
  },
  /*{
    path: 'teleprompter',
    loadChildren: () => import('./teleprompter/teleprompter.module').then(m => m.TeleprompterModule)
  }*/]
},
  {
  path: '',
  component: AppBlankComponent,
  children: [{
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  }],
  data: { isPublic: true }
},
  {
  path: '',
  component: AppBlankComponent,
  children: [{
    path: 'teleprompter',
    loadChildren: () => import('./teleprompter/teleprompter.module').then(m => m.TeleprompterModule)
  }],
  data: { isPublic: true }
},
  {
  path: '**',
  redirectTo: 'authentication/404'
}];
