import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';

import { Subscription } from 'rxjs';

import {Action, ActionsSubject, Store} from '@ngrx/store';
import * as userReducer from './authentication/store/user.reducer';
import * as userActions from './authentication/store/user.actions';
import { UserState } from './store/app.state';
import { AuthService } from './shared/services/auth.service';
import {Actions, ofType} from "@ngrx/effects";
import {User} from "./shared/models/user";
import {DsService} from "./shared/services/ds.service";
import {skip} from "rxjs/operators";
import {DsPublishService} from "./settings/services/ds-publish.service";
import {DsListenerService} from "./settings/services/ds-listener.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  loggedInSub: Subscription;
  user: User;

  // ds stuff
  userUpdates;

  constructor (
    private auth: AuthService,
    private store: Store<any>,
    private router: Router,
    private _location: Location,
    private updates$: Actions,
    private ds: DsService,
    private actionListener$: ActionsSubject,
    private dsPublisher: DsPublishService,
    private dsListener: DsListenerService
  ) {}

  ngOnInit() {
    console.log('app comp init!');
    this.registerLiveUpdates();
    this.loggedInSub = this.auth.loggedIn$.subscribe(
      loggedIn => {
        this.store.dispatch(new userActions.GetUserSettings());
        // redirect if logged in
        // this._location.back();

        this.store.select(store => store.UserState)
          .subscribe(user => {
            this.user = user.user;
            /*console.log('-------------xxxxxxxxx---------------->>>>>DS USER::',this.user.id);*/
          });
      });
  }

  registerLiveUpdates() {

    /*this.updates$
      .pipe(ofType(userActions.USER_GET_SETTINGS_SUCCESS))
      .subscribe((data: { payload:User }) => {
        this.user = data.payload;
        console.log("==================================================================> USER LOGIN", this.user)

        // Login without credentials to deepstream
        this.ds.login(null, null);
        this.userUpdates = this.ds.getRecord('live-updates/'+this.user.id);

        this.userUpdates.subscribe(msg => {
          /!*console.log(msg)*!/
          console.log("==================================================================> USER UPDATES", msg)
          /!*msg.forEach(element => {

            this.ds.getRecord(element).subscribe((m) => {
              console.log("==================================================================> USER UPDATES", m)
            });
          });*!/
        });
      });*/


    /*deepstream listener*/
    this.updates$
      .pipe(ofType(userActions.USER_GET_SETTINGS_SUCCESS))
      .subscribe((data: { payload: User }) => {
        // Login without credentials to deepstream
        console.log('-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x- app comp live update subscribed! -x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-');
        this.ds.login();
        this.userUpdates = this.ds.getRecord('live-updates/0');

        this.userUpdates.subscribe((data: {toUser: number, fromUser: User, action: Action, date: string}) => {
        /*this.userUpdates.subscribe((data) => {*/
          /*console.log(msg)*/
          console.log("==================================================================> DS UPDATES", data);
          this.dsListener.listenAction(data.action, data.fromUser);
          /*msg.forEach(element => {

            this.ds.getRecord(element).subscribe((m) => {
              console.log("==================================================================> USER UPDATES", m)
            });
          });*/
        });
      });


    // tap into all ngrx actions
    this.actionListener$.pipe(
      //skip(1) // optional: skips initial logging done by ngrx
    ).subscribe((action) => {
      //console.info('ngrx action', action)
      this.dsPublisher.publishAction(action, this.user);
    });
  }

  ngOnDestroy(): void {
    this.loggedInSub.unsubscribe();
  }
}
