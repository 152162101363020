<!-- <h3 mat-dialog-title>Add News to {{data.name}}</h3> -->

<mat-toolbar color="secondary" class="no-shadow">
    <mat-toolbar-row>
        <span fxFlex fxShow="false" fxShow.gt-xs>Add News to Ticker</span>
        <div fxShow="false" fxShow.gt-xs>
            <mat-slide-toggle labelPosition="after" color="secondary" [(ngModel)]="dhivehiEnabled" (ngModelChange)="changeLanguage()">{{tickerNews.language.language_code}}</mat-slide-toggle>
        </div>
    </mat-toolbar-row>
  </mat-toolbar>

<div mat-dialog-content>
  <!-- <div fxLayout="row" fxLayoutWrap="wrap"> -->
      <!-- title column -->
      <!-- <div fxFlex.gt-sm="100" fxFlex="100">
          Add News to {{data.name}}
      </div> -->
      <!-- hr column -->
      <!-- <div fxFlex.gt-sm="100" fxFlex="100" class="m-t-20">
        <mat-divider></mat-divider>
      </div> -->
      <!-- column -->
  <!-- </div> -->

  <div style="margin-top:20px;" fxLayout="row" fxLayoutWrap="wrap">
      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field appearance="outline">
          <mat-label>Ticker News</mat-label>
          <textarea matInput mat-autosize matAutosizeMinRows="5" #dhvInput required
            [appDnrmsThaana]="dhivehiEnabled"
            [(ngModel)]="tickerNews.news"
            name="dhvInput"></textarea>
        </mat-form-field>
      </div>
  </div>

  <!-- <div fxLayout="row" fxLayoutWrap="wrap">
      <div fxFlex.gt-sm="100" fxFlex="100">
          <code>{{tickerNews|json}}</code>
      </div>
  </div> -->

</div>
<!-- actions column -->
<div align="end" mat-dialog-actions align="end">
  <button mat-button color="basic" (click)="closeDialog(true)">Cancel</button>
  <button mat-raised-button color="primary" (click)="doAdd(data.id)">Add</button>

  <!-- <button mat-button color="basic" *ngIf="data.newsstatus.status != 'published' && data.newsstatus.status != 'deleted'" routerLink="/news/{{data.id}}" (click)="closeDialog(true)">Edit</button> -->
</div>
