import { BehaviorSubject ,  Observable ,  SubscriptionLike as ISubscription ,  Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { User, Role } from '../models/user';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/app.state';

import * as userActions from '../../authentication/store/user.actions';
import { takeUntil } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {

  private sessionExpiryTime: number = 1; //token expiry time local
  private checkSessionExpiry: boolean = false;

  public accessToken: string;
  public id = '';
  public name = 'Guest';
  public email = '';
  public roles: string;
  public roles_all: string;
  private subscription: ISubscription;

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private store: Store<any>,
    private http: HttpClient
  ) {}

  public destroy(): void {
    sessionStorage.clear();

    this.accessToken = null;
    this.name = 'Guest';
    this.email = '';
    this.roles = '';
    this.roles_all = '';
  }

  public saveSession(loginUser: User) {
    // set token expiry time
    const date = new Date();
    date.setFullYear(date.getFullYear() + this.sessionExpiryTime);

    sessionStorage.setItem('id',         loginUser.id);
    sessionStorage.setItem('name',       loginUser.name);
    sessionStorage.setItem('email',      loginUser.email);
    if(loginUser.token){
      sessionStorage.setItem('dnms_token', loginUser.token.token);
    }
    sessionStorage.setItem('roles',      JSON.stringify(loginUser.roles));
    sessionStorage.setItem('expiry',     date.toJSON());
    //sessionStorage.setItem('roles_all', JSON.stringify(loginUser.roles_a));

    //profile
    sessionStorage.setItem('profile',JSON.stringify(loginUser.profile));

    this.id          = this.getSession('id');
    this.name        = this.getSession('name');
    this.email       = this.getSession('email');
    this.accessToken = this.getSession('dnms_token.token');
    this.roles       = this.getSession('roles');
    this.roles_all   = this.getSession('roles_all');

    return true;
  }

  /**
   * pass user data as an observable (from session storage)
   * called when page is refreshed for user state to update
   */
  public getUserSetting() {
    if (!this.getSession('dnms_token')) {
      return this.getUserLogoutSetting();
      // return false;
    }

    return new Observable(observer => {
      let profile = JSON.parse(this.getSession('profile'));
      console.log(profile)
      const user = {
        id:      this.getSession('id'),
        email:   this.getSession('email'),
        name:    this.getSession('name'),
        token:   { token: this.getSession('dnms_token') },
        roles:   JSON.parse(this.getSession('roles')),
        roles_a: JSON.parse(this.getSession('roles_all')),
        profile: {
          'id':          profile.id,
          'user_id':     profile.user_id,
          'dob':         profile.dob,
          'gender':      profile.gender,
          'insta':       profile.insta,
          'fb':          profile.fb,
          'twitter':     profile.twitter,
          'email':       profile.email,
          'phone':       profile.phone,
          'profilepic':  profile.profilepic,
          'coverimage':  profile.coverimage,
        }
      };
        observer.next(user);
        observer.complete();
    });

  }

  /**
   *  update user data from server
   *  called when user updates their profile
   */
  public updateUserSettings() {
    return this.http.get<any>(environment.apiUrl + 'userdata/all');
  }

  /**
   * Check Expiry
   * experiment to check if users token has expired
   *
   * year, month, day
   * hour,min, sec
   */
  checkExpiry() {
    if(this.checkSessionExpiry) {
      let nowDate = new Date();
      //nowDate.setMinutes(nowDate.getMinutes() + this.sessionExpiryTime);

      let sessionDate = new Date(sessionStorage.getItem('expiry'));
      // console.log(nowDate)
      // console.log(new Date(sessionDate))

      if(nowDate.getFullYear() > sessionDate.getFullYear()) {
        console.log('this!!!!');
        this.CallLogoutState();
        return false;
      }else if(nowDate.getMonth() > sessionDate.getMonth()) {
        console.log('this!!!!');
        this.CallLogoutState();
        return false;
      }else if(nowDate.getDay() > sessionDate.getDay()) {
        console.log('this!!!!');
        this.CallLogoutState();
        return false;
      }

      let nowTime     = nowDate.getHours()+':'+nowDate.getMinutes()+':'+nowDate.getSeconds();
      let sessionTime = sessionDate.getHours()+':'+sessionDate.getMinutes()+':'+sessionDate.getSeconds();
      //if(nowTime > sessionTime) {
      var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
      if (parseInt(nowTime.replace(regExp, "$1$2$3")) > parseInt(sessionTime .replace(regExp, "$1$2$3"))){
        console.log('this!!!!', nowTime, sessionTime );
        this.CallLogoutState();
        return false;
      }
    }

    return true;
  }

  CallLogoutState(){
    this.destroySubject$.next();
    //this.subscription.unsubscribe();
    this.store.dispatch(new userActions.UserLogout());
  }

  /**
   * pass null user data as an observable
   * called when user logs out
   */
  public getUserLogoutSetting() {

    return new Observable(observer => {
      const user = {
        email: null,
        name: null,
        token: { token: null },
        roles: null,
        roles_a: null
      };
      observer.next(user); // This method same as resolve() method from Angular 1
      // console.log('am done');
      observer.complete(); // to show we are done with our processing
      // observer.error(new Error("error message"));
    });

  }

  //get a key from session storage
  public getSession(key: string) {
    return sessionStorage.getItem(key);
  }

  /**
   *  Check if the logged in user have a given role or permission
   */
  public checkRole(role: string) {
    let found = false;

    if (this.checkExpiry()) {

      this.store.select(state => state.UserState.user.roles)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(list => {
        if(list){
        //if(Object.keys(list).length > 0){
            list.forEach((user_role: Role) => {
              if (user_role.role === role) {
                found = true;
              }
            });
        }
      });

    }else{
      //this.subscription.unsubscribe();
    }

    return found;
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
    this.destroySubject$.next();
  }

}
