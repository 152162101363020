import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
declare var deepstream: any;

@Injectable({
  providedIn: 'root'
})
export class DsService {

  /*
  * Working version of deepstream = 2.1.2
  * docker run -p 6020:6020 deepstreamio/deepstream.io:2.1.2
  * */

  private ds;
  private deepstreamUrl = environment.deepstream;
  public dsInstance;

  constructor() {
    this.ds = this.dsInstance = deepstream(this.deepstreamUrl);
  }

  login(credentials?, loginHandler?) {
    // {username: 'chris', password:'password'}
    // this.ds.login(credentials, loginHandler);
    this.ds.login();
  }

  getRecord(name) {
    return this.ds.record.getRecord(name);
  }

  getAll(name) {
    return this.ds.record.get(name);
  }

  getList(name) {
    return this.ds.record.getList(name);
  }

  hasRecord(name: string) {
    let has = false;
    this.ds.record.has(name, (error, has_record) => {
      has = true;
    });
    return has;

    /*return this.ds.record.has(name);*/
  }

  sendLiveUpdate(toUser: number, fromUser: number, action: string) {
    /*const recordName = 'chat/' + this.ds.dsInstance.getUid();*/
    // const recordName = 'chat/' + this.userData.id;
    const listName = 'live-updates';
    const recordList = this.getList(listName);

    const record = this.getRecord(listName + '/' + toUser);
    record.set({toUser, fromUser, action});
    // Update the chats list
    recordList.addEntry(listName + '/' + toUser);
  }

  discardRecord(name) {
    return this.ds.discard(name);
  }
}
