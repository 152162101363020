export class User {
  id?: string;
  email?: string;
  password?: string;
  token?: Token;
  name?: string;
  roles_a?: any;
  roles?: Role[];
  profile?: Profile;

  constructor() {
    this.id = null;
  }
}

export class Token {
  token?: string;
}

export class Role {
  role: string;
  display_name: string;
  permissions: string[];
}

export class Profile{
  id?: string;
  user_id?: string;
  dob?: string;
  gender?: string;
  fb?: string;
  insta?: string;
  twitter?: string;
  email?: string;
  phone?: string;
  profilepic?: string;
  coverimage?: string;

  constructor(){
    this.user_id    = null;
    this.dob        = null;
    this.gender     = null;
    this.fb         = null;
    this.insta      = null;
    this.twitter    = null;
    this.email      = null;
    this.phone      = null;
    this.profilepic = '';
    this.coverimage = '';

  }
}
