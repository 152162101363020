import {Action} from "@ngrx/store";
import {NewsList} from "../../news/models/news";
import {TeleprompterSettings} from "../../store/app.state";

export const LOAD_PROMPTER_NEWS = '[Teleprompter] LOAD_PROMPTER_NEWS';
export const LOAD_PROMPTER_NEWS_SUCCESS = '[Teleprompter] LOAD_PROMPTER_NEWS_SUCCESS';
export const LOAD_PROMPTER_NEWS_ERROR = '[Teleprompter] LOAD_PROMPTER_NEWS_ERROR';

export const RE_LOAD_PROMPTER_NEWS = '[Teleprompter RELOAD] RE_LOAD_PROMPTER_NEWS';

/** --------------------------------------------------> LOADING PROMPTER NEWS */
export class LoadPrompterNews implements Action {
  readonly type = LOAD_PROMPTER_NEWS;
}

export class LoadPrompterNewsSuccess implements Action {
  readonly type = LOAD_PROMPTER_NEWS_SUCCESS;
  constructor(public payload: NewsList) {}
}

export class LoadPrompterNewsError implements Action {
  readonly type = LOAD_PROMPTER_NEWS_ERROR;
  constructor(public payload: any) {}
}

/** --------------------------------------------------> LOADING PROMPTER NEWS */
export class RELoadPrompterNews implements Action {
  readonly type = RE_LOAD_PROMPTER_NEWS;
}

export type All = LoadPrompterNews | LoadPrompterNewsSuccess | LoadPrompterNewsError
  | RELoadPrompterNews;
