import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {ListNews, News, NewsParticular, Ticker} from "../../../news/models/news";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import * as settingsActions from "../../../settings/store/settings.actions";

@Component({
  selector: 'app-news-particular-dialog',
  templateUrl: './news-particular-dialog.component.html',
  styleUrls: ['./news-particular-dialog.component.css']
})
export class NewsParticularDialogComponent implements OnInit {
  onSave = new EventEmitter();
  particulars: NewsParticular[] = [];
  particularSettings: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<NewsParticularDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ListNews,
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.store.select(store => store.AppSettings.newsParticulars)
      .subscribe((response) => {
        this.particularSettings = response;
      });

    this.particularSettings.forEach(data => {
      let found: boolean = false;

      if (this.data.news.particulars) {
        this.data.news.particulars.forEach(particular => {
          if (particular.key === data) {
            this.particulars.push(new NewsParticular(null, this.data.news.id, data, particular.value))
            found = true;
          }
        });
      }

      if (!found) {
          this.particulars.push(new NewsParticular(null, this.data.news.id, data, null))
      }
    });
  }

  save() {
    this.closeDialog(true);
    this.store.dispatch(new settingsActions.SaveParticulars(this.particulars));
    this.onSave.emit();
  }

  closeDialog(confirmation: boolean) {
    this.dialogRef.close(confirmation);
  }

}
