import { Injectable } from '@angular/core';
import {Action, Store} from "@ngrx/store";
import * as newsAction from "../../news/store/news.actions";
import * as editorAction from "../../editor/store/news-editor.actions";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DsService} from "../../shared/services/ds.service";

import * as editorActions from "../../editor/store/news-editor.actions";
import * as settingsActions from "../../settings/store/settings.actions";
import * as prompterActions from "../../teleprompter/store/teleprompter.actions";
import {User} from "../../shared/models/user";

@Injectable({
  providedIn: 'root'
})
export class DsListenerService {
  private apiUrl = environment.apiUrl;
  private user: User;

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private dsService: DsService
  ) {}


  listenAction(action: Action, fromUser: User) {
    this.store.select(store => {
      this.user = store.UserState.user
    })

    switch (action.type) {
      case newsAction.ADD_NEWS_SUCCESS:
        this.store.dispatch(new editorAction.GetReviewNews());
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      case editorAction.APPROVE_NEWS:
        this.store.dispatch(new editorAction.GetReviewNews());
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      case editorAction.UPDATE_LIST_ORDER_SUCCESS:
        if (fromUser.id === this.user.id)
          return;
        this.store.dispatch(new editorAction.GetLists());
        /*this.store.dispatch(new editorActions.GetSelectedList());*/
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      case editorAction.ADD_TO_LIST_SUCCESS:
        if (fromUser.id === this.user.id)
          return;
        this.store.dispatch(new editorAction.GetLists());
        /*this.store.dispatch(new editorActions.GetSelectedList());*/
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      case editorAction.REMOVE_FROM_LIST_SUCCESS:
        if (fromUser.id === this.user.id)
          return;
        this.store.dispatch(new editorAction.GetLists());
        /*this.store.dispatch(new editorActions.GetSelectedList());*/
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      case editorAction.GET_LISTS_SUCCESS:
        if (fromUser.id === this.user.id)
          return;
        /*this.store.dispatch(new editorAction.GetLists());*/
        this.store.dispatch(new editorActions.GetSelectedList());
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      case settingsActions.UPDATE_PROMPTER_SETTINGS_SUCCESS:
        /*this.store.dispatch(new editorAction.GetLists());*/
        this.store.dispatch(new settingsActions.GetPrompterSettings());
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      case prompterActions.RE_LOAD_PROMPTER_NEWS:
        /*this.store.dispatch(new editorAction.GetLists());*/
        this.store.dispatch(new prompterActions.LoadPrompterNews());
        console.log('------------------------------------------>>>>>DS LISTEN::',action.type, action);
        break;

      default:
        console.log('DEFAULT:------------------------------------------>>>>>DS LISTEN DEFAULT::',action.type, action);
        break
    }
  }
}
