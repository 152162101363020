import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {News} from "../../../news/models/news";

@Component({
  selector: 'app-view-news-dialog',
  templateUrl: './view-news-dialog.component.html'
})
export class ViewNewsDialogComponent {
  onDelete = new EventEmitter();
  onUndo = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ViewNewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: News
  ) { }

  closeDialog(confirmation: boolean) {
    this.dialogRef.close(confirmation);
  }

  doDelete(id) {
    this.closeDialog(false);
    this.onDelete.emit(id);
  }

  doUndo(id) {
    this.closeDialog(false);
    this.onUndo.emit(id);
  }

}
