// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  /* Local System MacBook Pro */
  /*apiUrl: 'http://localhost:8189/api/',
  storageUrl: 'http://localhost:8189/storage/',
  omxApiUrl: 'http://192.168.18.37:8010',
  deepstream: '192.168.18.37:6020',*/

    /* Local System MacBook Pro 2 */
  // ASLU LOCAL
  /*apiUrl: 'http://192.168.10.40:83/api/',
  storageUrl: 'http://192.168.10.40:83/storage/',
  omxApiUrl: 'http://192.168.18.37:8010',
  deepstream: '192.168.10.40:6020/deepstream',*/

    // ASLU PUBLIC
    // apiUrl: 'http://103.110.109.174:83/api/',
    // storageUrl: 'http://103.110.109.174:83/storage/',
    // omxApiUrl: 'http://103.110.109.174:8010',
    // deepstream: 'wss://103.110.109.174:6020/deepstream',

    apiUrl: 'https://aslu-api.nrs.news/api/',
    storageUrl: 'https://aslu-api.nrs.news/storage/',
    omxApiUrl: 'https://aslu-api.nrs.news:8010',
    deepstream: 'wss://aslu-api.nrs.news:6020/deepstream',


  /*
  * Normal start
  * =============
  * - docker container run command on ubuntu server at RTV
  * -----------------
  * sudo docker run -d -p 6020:6020 deepstreamio/deepstream.io:2.2.1
  /* ======================================================================================
  /* ======================================================================================
  /* ======================================================================================
  * Rerun after reboot or on crash
  * ------------------------------
  * docker container run command on ubuntu server at RTV
  * sudo docker run -dit --restart unless-stopped -d -p 6020:6020 deepstreamio/deepstream.io:2.2.1
  * */
  /*deepstream: '203.104.25.202:6020',*/

  /*RTV Local*/
  /*apiUrl: 'http://192.168.0.8:91/api/',
  storageUrl: 'http://192.168.0.8:91/storage/',
  omxApiUrl: 'http://192.168.0.79:8010',*/
  /*deepstream: '192.168.0.79:6020/deepstream',*/
  /*deepstream: 'ws://local.raajje.mv:6020/deepstream',*/

  /*apiUrl: 'http://203.104.25.202:84/api/',
  storageUrl: 'http://203.104.25.202:84/nrs/storage/',*/
  pusher: {
    key: '5289e5ee971f625d85fa',
    cluster: 'ap2',
  },

  firebaseConfig: {
    apiKey: 'AIzaSyAIGm6zOh3hIyxjPgnMk49I3GKGOemtJz8',
    authDomain: 'dnrs-rtv.firebaseapp.com',
    projectId: 'dnrs-rtv',
    storageBucket: 'dnrs-rtv.appspot.com',
    messagingSenderId: '1020243579171',
    appId: '1:1020243579171:web:5c846ab2199fbc4dcf7c96',
    measurementId: 'G-DCPJM2FPR0'
  }
};
