<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir" [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark }">
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar telative">
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="/">
                <!-- Logo icon -->
                <b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!--<img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">-->
                    <img src="assets/images/logo-sm_white_inv.png" alt="homepage" class="dark-logo">
                    <!-- Light Logo icon -->
                    <img src="assets/images/logo-sm_white_inv.png" alt="homepage" class="light-logo">
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <!--<span fxShow="false" fxShow.gt-xs>-->
                    <!-- dark Logo text -->
                    <!--<img src="assets/images/logo-text.png" alt="homepage" class="dark-logo">-->
                    <!-- Light Logo text -->
                    <!--<img src="assets/images/logo-text.png" class="light-logo" alt="homepage">-->
                <!--</span>-->
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->

        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <!-- ============================================================== -->
        <!-- Search - style you can find in header.scss -->
        <!-- ============================================================== -->
        <!-- <button mat-icon-button class="srh-btn">
            <mat-icon>search</mat-icon>
        </button>
        <form class="app-search">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="cl-srh-btn">
                <i class="ti-close"></i>
            </a>
        </form> -->
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
        <!-- ============================================================== -->
        <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        <!-- <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
            <mat-icon>settings</mat-icon>
        </button> -->
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
        [ngClass]="{'minisidebar': minisidebar}">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
            [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true" (close)="sidebarOpened = false"
            [perfectScrollbar]="config">

            <app-sidebar class="app-sidebar"></app-sidebar>

        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
            <div class="scroll">
                <mat-nav-list>

                    <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
                    </mat-list-item>
                    <!--<mat-list-item>
                    <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
                  </mat-list-item>    -->
                    <mat-divider></mat-divider>
                    <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red</mat-checkbox>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal
                            Green</mat-checkbox>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue</mat-checkbox>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </mat-sidenav>

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">

                <div class="page-content">

                    <router-outlet>
                        <app-spinner></app-spinner>
                    </router-outlet>

                </div>

        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>
