import { News } from './../models/news';
import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import * as NewsActions from './news.actions';
import { NewsState } from '../../store/app.state';





export const initialState: NewsState = {
    myNews: [],
    myNewsPageState: {currentPage: null, perPage: null, lastPages: null, totalResults: null},
    trashNews: [],
    trashNewsPageState: {currentPage: null, perPage: null, lastPages: null, totalResults: null},
    isLoaded: false,
    isMyNewsLoaded: false,
    isTrashLoaded: false,
    tickers: null,
  };

export type Actions = NewsActions.All;
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export function reducer(state = initialState, action: ActionWithPayload<any>): NewsState {
  /*console.log(state, action);
  console.log('News REDUCER Call!')*/

  switch (action.type) {

    case NewsActions.GET_MY_NEWS: {
      return {
        ...state,
        myNewsPageState: {
          currentPage: action.payload.currentPage,
          perPage: action.payload.perPage,
        },
        isMyNewsLoaded: false,
        isLoaded: false
      };
    }

    case NewsActions.GET_MY_NEWS_SUCCESS: {
      return {
        ...state,
        myNews: action.payload.data,
        myNewsPageState: {
          currentPage: action.payload.current_page,
          perPage: Number(action.payload.per_page),
          lastPages: action.payload.last_page,
          totalResults: action.payload.total,
        },
        isMyNewsLoaded: true,
        isLoaded: true
      };
    }

    case NewsActions.GET_TRASH_NEWS: {
      return {
        ...state,
        trashNewsPageState: {
          currentPage: action.payload.currentPage,
          perPage: action.payload.perPage,
        },
        isTrashLoaded: false,
        isLoaded: false
      };
    }

    case NewsActions.GET_TRASH_NEWS_SUCCESS: {
      return {
        ...state,
        trashNews: action.payload.data,
        trashNewsPageState: {
          currentPage: action.payload.current_page,
          perPage: Number(action.payload.per_page),
          lastPages: action.payload.last_page,
          totalResults: action.payload.total,
        },
        isTrashLoaded: true,
        isLoaded: true
      };
    }

    case NewsActions.DELETE_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }

    case NewsActions.DELETE_NEWS_SUCCESS: {
      return {
        ...state,
        myNews: state.myNews.filter( (news) => news.id !== action.payload.id ),
        trashNews: [
          ...state.trashNews,
          ...Object.assign(state.myNews.filter((news) => news.id === action.payload.id), { newsstatus: { status: 'deleted' } })
        ],
        isLoaded: true,
        isMyNewsLoaded: false,
        isTrashLoaded: false
      };
    }

    case NewsActions.RESTORE_NEWS_SUCCESS: {
      return {
        ...state,
        myNews: [
          ...state.myNews,
          ...(state.trashNews.filter( (news) => news.id === action.payload.id ))
        ],
        trashNews: state.trashNews.filter( (news) => news.id !== action.payload.id ),
        isLoaded: false,
        isMyNewsLoaded: false,
        isTrashLoaded: false
      };
    }

    /**
     * ----------------------------------------------------------------------------------
     * ---------------------------------------------------------------------> Ticker News
     * ----------------------------------------------------------------------------------
     */
    /**
     * ---------------------------------------------------------------------> GET TICKERS
     */
    case NewsActions.GET_TICKERS: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.GET_TICKERS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.GET_TICKERS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * ---------------------------------------------------------------------> ADD TICKER NEWS
     */
    case NewsActions.ADD_TICKER_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.ADD_TICKER_NEWS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.ADD_TICKER_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * ---------------------------------------------------------------------> UPDATE TICKER NEWS
     */
    case NewsActions.UPDATE_TICKER_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.UPDATE_TICKER_NEWS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.UPDATE_TICKER_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * ---------------------------------------------------------------------> APPROVE TICKER NEWS
     */
    case NewsActions.APPROVE_TICKER_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.APPROVE_TICKER_NEWS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.APPROVE_TICKER_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * ---------------------------------------------------------------------> DISAPPROVE TICKER NEWS
     */
    case NewsActions.DISAPPROVE_TICKER_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.DISAPPROVE_TICKER_NEWS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.DISAPPROVE_TICKER_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * ---------------------------------------------------------------------> DELETE TICKER NEWS
     */
    case NewsActions.DELETE_TICKER_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.DELETE_TICKER_NEWS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.DELETE_TICKER_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * ---------------------------------------------------------------------> ADD TICKER
     */
    case NewsActions.ADD_TICKER: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.ADD_TICKER_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.ADD_TICKER_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }



    /**
     * ---------------------------------------------------------------------> DELETE TICKER WITH ALL DATA
     */
    case NewsActions.DELETE_TICKER: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case NewsActions.DELETE_TICKER_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        tickers: action.payload
      };
    }
    case NewsActions.DELETE_TICKER_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }



    // when set to false myNews will be fetched from api
    case NewsActions.CHANGE_NEWS_LOADED: {
      return {
        ...state,
        isLoaded: action.payload.isLoaded
      };
    }
    case NewsActions.CHANGE_MY_NEWS_LOADED: {
      return {
        ...state,
        isMyNewsLoaded: action.payload.isMyNewsLoaded
      };
    }
    case NewsActions.CHANGE_TRASH_NEWS_LOADED: {
      return {
        ...state,
        isTrashLoaded: action.payload.isTrashNewsLoaded
      };
    }

    case NewsActions.DESTROY_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }

    case NewsActions.DESTROY_NEWS_SUCCESS: {
      return {
        ...state,
        trashNews: state.trashNews.filter( (news) => news.id !== action.payload.id ),
        isLoaded: true
      };
    }

    case NewsActions.LOGOUT_NEWS_STATE: {
      // return {
      //   myNews: [],
      //   myNewsPageState: {currentPage: null, perPage: null, lastPages: null, totalResults: null},
      //   trashNews: [],
      //   trashNewsPageState: {currentPage: null, perPage: null, lastPages: null, totalResults: null},
      //   isLoaded: false,
      //   isMyNewsLoaded: false,
      //   isTrashLoaded: false
      // };
      return initialState;
    }

    default: {
      return state;
    }

  }
}

export const getNewsState = createFeatureSelector<NewsState>('NewsState');

export const getMyNews = createSelector(
  getNewsState,
  (state: NewsState) => state.myNews
);

export const getNewsById = (newsBox, id) => createSelector(getNewsState, (allItems) => {
  if (allItems) {
    return allItems[newsBox].find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});

export const getMyNewsByStatus = (status) =>
  createSelector(getNewsState, state => state.myNews.filter(x => x.newsstatus.status === status));

