<h3 mat-dialog-title>{{data.news_name}}</h3>
<div mat-dialog-content>
  <div fxLayout="column" fxLayoutWrap="wrap">
      <!-- title column -->
      <div fxFlex.gt-sm="100" fxFlex="100" [class.dhv-title]="data.language.language_code == 'Dhv'">
          {{data.heading}}
      </div>
      <!-- hr column -->
      <div fxFlex.gt-sm="100" fxFlex="100" class="m-t-20">
        <mat-divider></mat-divider>
      </div>
      <!-- news body column -->
      <div fxFlex.gt-sm="100" fxFlex="100" class="m-t-20">
          <div [innerHTML]="data.body" style="white-space: pre-wrap" [class.dhv]="data.language.language_code == 'Dhv'"></div>
      </div>
      <!-- hr column -->
      <div fxFlex.gt-sm="100" fxFlex="100" class="m-t-20">
        <mat-divider></mat-divider>
      </div>
      <!-- news bars column -->
      <div fxFlex.gt-sm="100" fxFlex="100" class="m-t-20">
          <mat-card>
              <mat-card-content>
                  <mat-card-title>News Bar</mat-card-title>
              </mat-card-content>
              <mat-tab-group>
                  <mat-tab *ngFor="let bar of data.newsbar; let i = index" label="Bar {{i+1}}">
                    <mat-card-content>
                      <p [class]="data.language.language_code == 'Dhv' ? 'dhv' : '' ">{{bar.title}}</p>
                      <!--<p [class]="data.language.language_code == 'Dhv' ? 'dhv m-t-20' : '' ">{{bar.detail}}</p>-->
                    </mat-card-content>
                  </mat-tab>
              </mat-tab-group>
          </mat-card>
      </div>
  </div>
</div>
<!-- actions column -->
<div align="end" mat-dialog-actions align="end">
  <button mat-button color="warn" *ngIf="data.newsstatus.status != 'published'" (click)="doDelete(data.id)">Delete</button>
  <button mat-button color="basic" *ngIf="data.newsstatus.status != 'published' && data.newsstatus.status != 'deleted'" routerLink="/news/{{data.id}}" (click)="closeDialog(true)">Edit</button>
  <button mat-raised-button color="primary" *ngIf="data.newsstatus.status === 'deleted'" (click)="doUndo(data.id)">Restore</button>
</div>
