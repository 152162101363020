import { Action } from '@ngrx/store';
import {News, NewsParticular, Ticker, TickersNews} from './../../news/models/news';

export const ADD_NEWS_SUCCESS = '[News] ADD_NEWS_SUCCESS';

export const GET_A_NEWS = '[News] GET_A_NEWS';
export const GET_A_NEWS_SUCCESS = '[News] GET_A_NEWS_SUCCESS';
export const GET_A_NEWS_ERROR = '[News] GET_A_NEWS_ERROR';

export const GET_MY_NEWS = '[News] GET_MY_NEWS';
export const GET_MY_NEWS_SUCCESS = '[News] GET_MY_NEWS_SUCCESS';
export const GET_MY_NEWS_ERROR = '[News] GET_MY_NEWS_ERROR';

export const GET_TRASH_NEWS = '[News] GET_TRASH_NEWS';
export const GET_TRASH_NEWS_SUCCESS = '[News] GET_TRASH_NEWS_SUCCESS';
export const GET_TRASH_NEWS_ERROR = '[News] GET_TRASH_NEWS_ERROR';

export const DELETE_NEWS = '[News] DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = '[News] DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_ERROR = '[News] DELETE_NEWS_ERROR';

export const RESTORE_NEWS = '[News] RESTORE_NEWS';
export const RESTORE_NEWS_SUCCESS = '[News] RESTORE_NEWS_SUCCESS';
export const RESTORE_NEWS_ERROR = '[News] RESTORE_NEWS_ERROR';

export const CHANGE_NEWS_LOADED = '[News] CHANGE_NEWS_LOADED';
export const CHANGE_MY_NEWS_LOADED = '[News] CHANGE_MY_NEWS_LOADED';
export const CHANGE_TRASH_NEWS_LOADED = '[News] CHANGE_TRASH_NEWS_LOADED';

export const DESTROY_NEWS = '[News] DESTROY_NEWS';
export const DESTROY_NEWS_SUCCESS = '[News] DESTROY_NEWS_SUCCESS';
export const DESTROY_NEWS_ERROR = '[News] DESTROY_NEWS_ERROR';

export const GET_TICKERS = '[News] GET_TICKERS';
export const GET_TICKERS_SUCCESS = '[News] GET_TICKERS_SUCCESS';
export const GET_TICKERS_ERROR = '[News] GET_TICKERS_ERROR';

export const ADD_TICKER_NEWS = '[News] ADD_TICKER_NEWS';
export const ADD_TICKER_NEWS_SUCCESS = '[News] ADD_TICKER_NEWS_SUCCESS';
export const ADD_TICKER_NEWS_ERROR = '[News] ADD_TICKER_NEWS_ERROR';

export const UPDATE_TICKER_NEWS = '[News] UPDATE_TICKER_NEWS';
export const UPDATE_TICKER_NEWS_SUCCESS = '[News] UPDATE_TICKER_NEWS_SUCCESS';
export const UPDATE_TICKER_NEWS_ERROR = '[News] UPDATE_TICKER_NEWS_ERROR';

export const APPROVE_TICKER_NEWS = '[News] APPROVE_TICKER_NEWS';
export const APPROVE_TICKER_NEWS_SUCCESS = '[News] APPROVE_TICKER_NEWS_SUCCESS';
export const APPROVE_TICKER_NEWS_ERROR = '[News] APPROVE_TICKER_NEWS_ERROR';

export const DISAPPROVE_TICKER_NEWS = '[News] DISAPPROVE_TICKER_NEWS';
export const DISAPPROVE_TICKER_NEWS_SUCCESS = '[News] DISAPPROVE_TICKER_NEWS_SUCCESS';
export const DISAPPROVE_TICKER_NEWS_ERROR = '[News] DISAPPROVE_TICKER_NEWS_ERROR';

export const DELETE_TICKER_NEWS = '[News] DELETE_TICKER_NEWS';
export const DELETE_TICKER_NEWS_SUCCESS = '[News] DELETE_TICKER_NEWS_SUCCESS';
export const DELETE_TICKER_NEWS_ERROR = '[News] DELETE_TICKER_NEWS_ERROR';

export const ADD_TICKER = '[News] ADD_TICKER';
export const ADD_TICKER_SUCCESS = '[News] ADD_TICKER_SUCCESS';
export const ADD_TICKER_ERROR = '[News] ADD_TICKER_ERROR';

export const DELETE_TICKER = '[News] DELETE TICKER';
export const DELETE_TICKER_SUCCESS = '[News] DELETE TICKER_SUCCESS';
export const DELETE_TICKER_ERROR = '[News] DELETE TICKER_ERROR';

export const LOGOUT_NEWS_STATE = '[News] LOGOUT_NEWS_STATE'; // clears news state when user logout is called




export class AddMyNewsSuccess implements Action {
  readonly type = ADD_NEWS_SUCCESS;
  constructor(public payload: News) {}
}

/** -------------------------------------------------->MY NEWS LOADING ACTIONS */
export class GetMyNews implements Action {
  readonly type = GET_MY_NEWS;
  constructor(public payload: { perPage?: any, currentPage?: number }) {}
}

export class GetMyNewsSuccess implements Action {
  readonly type = GET_MY_NEWS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetMyNewsError implements Action {
  readonly type = GET_MY_NEWS_ERROR;
}


/** -------------------------------------------------->TRASH NEWS LOADING ACTIONS */
export class GetTrashNews implements Action {
  readonly type = GET_TRASH_NEWS;
  constructor(public payload: { perPage?: any, currentPage?: number }) {}
}

export class GetTrashNewsSuccess implements Action {
  readonly type = GET_TRASH_NEWS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTrashNewsError implements Action {
  readonly type = GET_TRASH_NEWS_ERROR;
}


/**
 * -------------------------------------------------->GET ONE NEWS FROM STORE
 * --------------------------------------------------//TODO - remove later
 * */
export class GetNews implements Action {
  readonly type = GET_A_NEWS;
  constructor(public payload: string) {}
}

export class GetNewsSuccess implements Action {
  readonly type = GET_A_NEWS_SUCCESS;
  constructor(public payload: News) {}
}

export class GetNewsError implements Action {
  readonly type = GET_A_NEWS_ERROR;
}


/**
 * -------------------------------------------------->NEWS DELETING (TRASHING) ACTIONS,
 * -------------------------------------------------->sets news status to deleted
*/
export class DeleteNews implements Action {
  readonly type = DELETE_NEWS;
  constructor(public payload: { id: number }) {}
}

export class DeleteNewsSuccess implements Action {
  readonly type = DELETE_NEWS_SUCCESS;
  constructor(public payload: { id: number }) {}
}

export class DeleteNewsError implements Action {
  readonly type = DELETE_NEWS_ERROR;
}


/** -------------------------------------------------->NEWS RESTORING ACTIONS */
export class RestoreNews implements Action {
  readonly type = RESTORE_NEWS;
  constructor(public payload: { id: number }) {}
}

export class RestoreNewsSuccess implements Action {
  readonly type = RESTORE_NEWS_SUCCESS;
  constructor(public payload: { id: number }) {}
}

export class RestoreNewsError implements Action {
  readonly type = RESTORE_NEWS_ERROR;
}


/**
 * -------------------------------------------------->ACTIONS TO SET LOADING FLAGS
 * -------------------------------------------------->inorder for the controllers to load new news from api
 */
export class UpdateNewsLoaded implements Action {
  readonly type = CHANGE_NEWS_LOADED;
  constructor(public payload: { isLoaded: boolean }) {}
}
export class UpdateMyNewsLoaded implements Action {
  readonly type = CHANGE_MY_NEWS_LOADED;
  constructor(public payload: { isMyNewsLoaded: boolean }) {}
}
export class UpdateTrashNewsLoaded implements Action {
  readonly type = CHANGE_TRASH_NEWS_LOADED;
  constructor(public payload: { isTrashNewsLoaded: boolean }) {}
}


/**
 * -------------------------------------------------->ACTIONS FOR REMOVING NEWS FROM DATABASE
 * -------------------------------------------------->compleatly destroy them
 */
export class DestroyNews implements Action {
  readonly type = DESTROY_NEWS;
  constructor(public payload: { id: number }) {}
}

export class DestroyNewsSuccess implements Action {
  readonly type = DESTROY_NEWS_SUCCESS;
  constructor(public payload: { id: number }) {}
}

export class DestroyNewsError implements Action {
  readonly type = DESTROY_NEWS_ERROR;
}


/**
 * -------------------------------------------------->TICKER ACTIONS
 * -------------------------------------------------->users cannot remove, disable or enable ticker or ticker news
 * -------------------------------------------------->users can add ticker news, but the added news is marked unpublished (an editor have to publish the news)
 */
/**
 * -------------------------------------------------->GET TICKERS
 */
export class GetTickers implements Action {
  readonly type = GET_TICKERS;
}

export class GetTickersSuccess implements Action {
  readonly type = GET_TICKERS_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class GetTickersError implements Action {
  readonly type = GET_TICKERS_ERROR;
}

/**
 * -------------------------------------------------->ADD TICKER NEWS
 */
export class AddTickerNews implements Action {
  readonly type = ADD_TICKER_NEWS;
  constructor(public payload: TickersNews) {}
}

export class AddTickerNewsSuccess implements Action {
  readonly type = ADD_TICKER_NEWS_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class AddTickerNewsError implements Action {
  readonly type = ADD_TICKER_NEWS_ERROR;
}

/**
 * -------------------------------------------------->UPDATE TICKER NEWS
 */
export class UpdateTickerNews implements Action {
  readonly type = UPDATE_TICKER_NEWS;
  constructor(public payload: TickersNews) {}
}

export class UpdateTickerNewsSuccess implements Action {
  readonly type = UPDATE_TICKER_NEWS_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class UpdateTickerNewsError implements Action {
  readonly type = UPDATE_TICKER_NEWS_ERROR;
}

/**
 * -------------------------------------------------->APPROVE TICKER NEWS
 */
export class ApproveTickerNews implements Action {
  readonly type = APPROVE_TICKER_NEWS;
  constructor(public payload: { ticker_news_id: number }) {}
}

export class ApproveTickerNewsSuccess implements Action {
  readonly type = APPROVE_TICKER_NEWS_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class ApproveTickerNewsError implements Action {
  readonly type = APPROVE_TICKER_NEWS_ERROR;
}

/**
 * -------------------------------------------------->DISAPPROVE TICKER NEWS
 */
export class DisApproveTickerNews implements Action {
  readonly type = DISAPPROVE_TICKER_NEWS;
  constructor(public payload: { ticker_news_id: number }) {}
}

export class DisApproveTickerNewsSuccess implements Action {
  readonly type = DISAPPROVE_TICKER_NEWS_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class DisApproveTickerNewsError implements Action {
  readonly type = DISAPPROVE_TICKER_NEWS_ERROR;
}

/**
 * -------------------------------------------------->DELETE TICKER NEWS
 */
export class DeleteTickerNews implements Action {
  readonly type = DELETE_TICKER_NEWS;
  constructor(public payload: { ticker_news_id: number }) {}
}

export class DeleteTickerNewsSuccess implements Action {
  readonly type = DELETE_TICKER_NEWS_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class DeleteTickerNewsError implements Action {
  readonly type = DELETE_TICKER_NEWS_ERROR;
}

/**
 * -------------------------------------------------->ADD A TICKER
 */
export class AddTicker implements Action {
  readonly type = ADD_TICKER;
  constructor(public payload: Ticker) {}
}

export class AddTickerSuccess implements Action {
  readonly type = ADD_TICKER_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class AddTickerError implements Action {
  readonly type = ADD_TICKER_ERROR;
}

/**
 * -------------------------------------------------->DELETE A TICKER with all data
 */
export class DeleteTicker implements Action {
  readonly type = DELETE_TICKER;
  constructor(public payload: Ticker) {}
}

export class DeleteTickerSuccess implements Action {
  readonly type = DELETE_TICKER_SUCCESS;
  constructor(public payload: Ticker[]) {}
}

export class DeleteTickerError implements Action {
  readonly type = DELETE_TICKER_ERROR;
}





/** action called when user logs out of the system to clear the "NewsState" */
export class LogoutNewsState implements Action {
  readonly type = LOGOUT_NEWS_STATE;
}

export type All = GetNews | GetNewsSuccess | GetNewsError |
                  GetMyNews | GetMyNewsSuccess | GetMyNewsError |
                  DeleteNews | DeleteNewsSuccess | DeleteNewsError |
                  DestroyNews | DestroyNewsSuccess | DestroyNewsError |
                  UpdateNewsLoaded | UpdateMyNewsLoaded | UpdateTrashNewsLoaded |
                  DeleteTicker | DeleteTickerSuccess | DeleteTickerError |
                  UpdateTickerNews | UpdateTickerNewsError | UpdateTickerNewsSuccess |
                  LogoutNewsState;
