import { Action } from '@ngrx/store';
import { User } from './../../shared/models/user';

export const USER_LOGIN = '[User] USER_LOGIN';
export const USER_LOGIN_SUCCESS = '[User] USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = '[User] USER_LOGIN_ERROR';

export const USER_LOGOUT = '[User] USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = '[User] USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = '[User] USER_LOGOUT_ERROR';

export const USER_GET_SETTINGS = '[User] USER_GET_SETTINGS';
export const USER_GET_SETTINGS_SUCCESS = '[User] USER_GET_SETTINGS_SUCCESS';
export const USER_GET_SETTINGS_ERROR = '[User] USER_GET_SETTINGS_ERROR';

export const SERVER_USER_GET_SETTINGS = '[User] SERVER_USER_GET_SETTINGS';
export const SERVER_USER_GET_SETTINGS_SUCCESS = '[User] SERVER_USER_GET_SETTINGS_SUCCESS';
export const SERVER_USER_GET_SETTINGS_ERROR = '[User] SERVER_USER_GET_SETTINGS_ERROR';

export const USER_GET_ROLES = '[User] USER_GET_ROLES';

export const USER_401 = '[User] USER_401';


export class UserLogin implements Action {
  readonly type = USER_LOGIN;
  constructor(public payload: any) {}
}

export class UserLoginSuccess implements Action {
  readonly type = USER_LOGIN_SUCCESS;
  constructor(public payload: User) {}
}

export class UserLoginError implements Action {
  readonly type = USER_LOGIN_ERROR;
}

export class UserLogout implements Action {
  readonly type = USER_LOGOUT;
}

export class UserLogoutSuccess implements Action {
  readonly type = USER_LOGOUT_SUCCESS;
  //constructor(public payload: User) {}
}

export class UserLogoutError implements Action {
  readonly type = USER_LOGOUT_ERROR;
}

export class User401 implements Action {
  readonly type = USER_401;
}

export class GetUserSettings implements Action {
  readonly type = USER_GET_SETTINGS;
}

export class GetUserSettingsSuccess implements Action {
  readonly type = USER_GET_SETTINGS_SUCCESS;
  constructor(public payload: User) {}
}

export class GetUserSettingsError implements Action {
  readonly type = USER_GET_SETTINGS_ERROR;
}

export class GetUserRoles implements Action {
  readonly type = USER_GET_ROLES;
}



/**
 * Fetch user settings from server
 */
export class ServerGetUserSettings implements Action {
  readonly type = SERVER_USER_GET_SETTINGS;
}

export class ServerGetUserSettingsSuccess implements Action {
  readonly type = SERVER_USER_GET_SETTINGS_SUCCESS;
  constructor(public payload: User) {}
}

export class ServerGetUserSettingsError implements Action {
  readonly type = SERVER_USER_GET_SETTINGS_ERROR;
}

export type All = UserLogin | UserLoginSuccess | UserLoginError | GetUserSettings |
                  GetUserSettingsSuccess | GetUserSettingsError | GetUserRoles;
