import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {TeleprompterSettings} from "../../store/app.state";

@Injectable({
  providedIn: 'root'
})
export class TeleprompterService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getNews () {
    return this.http.get<any>(this.apiUrl + 'teleprompter/viewer');
  }

  getSettings () {
    return this.http.get<any>(this.apiUrl + 'teleprompter/settings');
  }

  updateSettings (settings: TeleprompterSettings) {
    return this.http.put<any>(this.apiUrl + 'teleprompter/settings/update', { data: settings });
  }
}
