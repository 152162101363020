import { RejectNotes } from './../notes-dialog/notes-dialog.component';
import { Action } from '@ngrx/store';
import { News, NewsList, ListOrder } from './../../news/models/news';
import {User} from "../../shared/models/user";
import {GET_MY_NEWS, GET_MY_NEWS_ERROR, GET_MY_NEWS_SUCCESS} from "../../news/store/news.actions";

export const GET_REVIEW_NEWS = '[NewsEditor] GET_REVIEW_NEWS';
export const GET_REVIEW_NEWS_SUCCESS = '[NewsEditor] GET_REVIEW_NEWS_SUCCESS';
export const GET_REVIEW_NEWS_ERROR = '[NewsEditor] GET_REVIEW_NEWS_ERROR';

export const APPROVE_NEWS = '[NewsEditor] APPROVE_NEWS';
export const APPROVE_NEWS_SUCCESS = '[NewsEditor] APPROVE_NEWS_SUCCESS';
export const APPROVE_NEWS_ERROR = '[NewsEditor] APPROVE_NEWS_ERROR';

export const REJECT_NEWS = '[NewsEditor] REJECT_NEWS';
export const REJECT_NEWS_SUCCESS = '[NewsEditor] REJECT_NEWS_SUCCESS';
export const REJECT_NEWS_ERROR = '[NewsEditor] REJECT_NEWS_ERROR';


/**
 * ------------------------------------------------------------------------------> LIST ACTIONS
 */
export const GET_LISTS = '[NewsEditor] GET_LISTS';
export const GET_LISTS_SUCCESS = '[NewsEditor] GET_LISTS_SUCCESS';
export const GET_LISTS_ERROR = '[NewsEditor] GET_LISTS_ERROR';

export const GET_LISTS_BY_DATE = '[NewsEditor] GET_LISTS_BY_DATE';
export const GET_LISTS_BY_DATE_SUCCESS = '[NewsEditor] GET_LISTS_BY_DATE_SUCCESS';
export const GET_LISTS_BY_DATE_ERROR = '[NewsEditor] GET_LISTS_BY_DATE_ERROR';

export const ADD_TO_LIST = '[NewsEditor] ADD_TO_LISTS';
export const ADD_TO_LIST_SUCCESS = '[NewsEditor] ADD_TO_LISTS_SUCCESS';
export const ADD_TO_LIST_ERROR = '[NewsEditor] ADD_TO_LISTS_ERROR';

export const REMOVE_FROM_LIST = '[NewsEditor] REMOVE_FROM_LIST';
export const REMOVE_FROM_LIST_SUCCESS = '[NewsEditor] REMOVE_FROM_LIST_SUCCESS';
export const REMOVE_FROM_LIST_ERROR = '[NewsEditor] REMOVE_FROM_LIST_ERROR';

export const UPDATE_LIST_ORDER = '[NewsEditor] UPDATE_LIST_ORDER';
export const UPDATE_LIST_ORDER_SUCCESS = '[NewsEditor] UPDATE_LIST_ORDER_SUCCESS';
export const UPDATE_LIST_ORDER_ERROR = '[NewsEditor] UPDATE_LIST_ORDER_ERROR';

export const UPDATE_SELECTED_LIST = '[NewsEditor] UPDATE_SELECTED_LIST';
export const UPDATE_SELECTED_LIST_SUCCESS = '[NewsEditor] UPDATE_SELECTED_LIST_SUCCESS';
export const UPDATE_SELECTED_LIST_ERROR = '[NewsEditor] UPDATE_SELECTED_LIST_ERROR';

export const GET_SELECTED_LIST = '[NewsEditor] GET_SELECTED_LIST';
export const GET_SELECTED_LIST_SUCCESS = '[NewsEditor] GET_SELECTED_LIST_SUCCESS';
export const GET_SELECTED_LIST_ERROR = '[NewsEditor] GET_SELECTED_LIST_ERROR';


export const CHANGE_REVIEW_NEWS_LOADED = '[NewsEditor] CHANGE_REVIEW_NEWS_LOADED';
export const LOGOUT_EDITOR_STATE = '[NewsEditor] LOGOUT_EDITOR_STATE';


/*------------------------------------------------------------------------------> search load */
export const LOAD_SEARCH = '[NewsEditor] LOAD_SEARCH';
export const LOAD_SEARCH_SUCCESS = '[NewsEditor] LOAD_SEARCH_SUCCESS';
export const LOAD_SEARCH_ERROR = '[NewsEditor] LOAD_SEARCH_ERROR';

export const SEARCH_NEWS_ACTION = '[NewsEditor] SEARCH_NEWS_ACTION';
export const SEARCH_NEWS_ACTION_SUCCESS = '[NewsEditor] SEARCH_NEWS_ACTION_SUCCESS';
export const SEARCH_NEWS_ACTION_ERROR = '[NewsEditor] SEARCH_NEWS_ACTION_ERROR';

/**
 * ---------------------------------------------------------------------------------------------------
 * ------------------------------------------------------------------------------> REVIEW NEWS ACTIONS
 * ---------------------------------------------------------------------------------------------------
 *
 *
 * ------------------------------------------------------------------------------> GET REVIEW NEWS
 */
export class GetReviewNews implements Action {
  readonly type = GET_REVIEW_NEWS;
  constructor() {}
}

export class GetReviewNewsSuccess implements Action {
  readonly type = GET_REVIEW_NEWS_SUCCESS;
  constructor(public payload: News[]) {}
}

export class GetReviewNewsError implements Action {
  readonly type = GET_REVIEW_NEWS_ERROR;
}

/**
 * ------------------------------------------------------------------------------> APPROVE NEWS
 */
export class ApproveNews implements Action {
  readonly type = APPROVE_NEWS;
  constructor(public payload: { id: number }) {}
}

export class ApproveNewsSuccess implements Action {
  readonly type = APPROVE_NEWS_SUCCESS;
  constructor(public payload: { id: number }) {}
}

export class ApproveNewsError implements Action {
  readonly type = APPROVE_NEWS_ERROR;
}

/**
 * ------------------------------------------------------------------------------> REJECT NEWS
 */
export class RejectNews implements Action {
  readonly type = REJECT_NEWS;
  constructor(public payload: RejectNotes ) {}
}

export class RejectNewsSuccess implements Action {
  readonly type = REJECT_NEWS_SUCCESS;
  constructor(public payload: RejectNotes) {}
}

export class RejectNewsError implements Action {
  readonly type = REJECT_NEWS_ERROR;
}


/**
 * -------------------------------------------------------------------------------------------------
 * ------------------------------------------------------------------------------> NEWS LIST ACTIONS
 * -------------------------------------------------------------------------------------------------
 *
 *
 * ------------------------------------------------------------------------------> GET LISTS
 */
export class GetLists implements Action {
  readonly type = GET_LISTS;
  constructor() {};
}

export class GetListsSuccess implements Action {
  readonly type = GET_LISTS_SUCCESS;
  constructor(public payload: NewsList[]) {}
}

export class GetListsError implements Action {
  readonly type = GET_LISTS_ERROR;
}

/**
 * ------------------------------------------------------------------------------> GET LISTS BY DATE
 */
export class GetListsByDate implements Action {
  readonly type = GET_LISTS_BY_DATE;
  constructor(public payload: { date: string }) {};
}

export class GetListsByDateSuccess implements Action {
  readonly type = GET_LISTS_BY_DATE_SUCCESS;
  constructor(public payload: NewsList[]) {}
}

export class GetListsByDateError implements Action {
  readonly type = GET_LISTS_BY_DATE_ERROR;
}

/**
 * ------------------------------------------------------------------------------> ADD NEWS TO LIST
 */
export class AddToList implements Action {
  readonly type = ADD_TO_LIST;
  constructor(public payload: { list_id: number, news_id: number }) {};
}

export class AddToListSuccess implements Action {
  readonly type = ADD_TO_LIST_SUCCESS;
  constructor(public payload: News[]) {}
}

export class AddToListError implements Action {
  readonly type = ADD_TO_LIST_ERROR;
}

/**
 * ------------------------------------------------------------------------------> REMOVE NEWS FROM LIST
 */
export class RemoveFromList implements Action {
  readonly type = REMOVE_FROM_LIST;
  constructor(public payload: { id: number }) {};
}

export class RemoveFromListSuccess implements Action {
  readonly type = REMOVE_FROM_LIST_SUCCESS;
  constructor(public payload: News[]) {}
}

export class RemoveFromListError implements Action {
  readonly type = REMOVE_FROM_LIST_ERROR;
}

/**
 * ------------------------------------------------------------------------------> UPDATE LIST ORDER
 */
export class UpdateListOrder implements Action {
  readonly type = UPDATE_LIST_ORDER;
  constructor(public payload: ListOrder[]) {};
}

export class UpdateListOrderSuccess implements Action {
  readonly type = UPDATE_LIST_ORDER_SUCCESS;
  constructor(public payload: News[]) {}
}

export class UpdateListOrderError implements Action {
  readonly type = UPDATE_LIST_ORDER_ERROR;
}

/**
 * ------------------------------------------------------------------------------> GET SELECTED LIST
 */
export class GetSelectedList implements Action {
  readonly type = GET_SELECTED_LIST;
  // constructor(public payload: { list_id: number }) {};
}

export class GetSelectedListSuccess implements Action {
  readonly type = GET_SELECTED_LIST_SUCCESS;
  constructor(public payload: { list_id: number }) {}
}

export class GetSelectedListError implements Action {
  readonly type = GET_SELECTED_LIST_ERROR;
}

/**
 * ------------------------------------------------------------------------------> UPDATE SELECTED LIST
 */
export class UpdateSelectedList implements Action {
  readonly type = UPDATE_SELECTED_LIST;
  constructor(public payload: { list_id: number }) {};
}

export class UpdateSelectedListSuccess implements Action {
  readonly type = UPDATE_SELECTED_LIST_SUCCESS;
  constructor(public payload: { list_id: number }) {}
}

export class UpdateSelectedListError implements Action {
  readonly type = UPDATE_SELECTED_LIST_ERROR;
}

/**
 * ------------------------------------------------------------------------------> LOAD SEARCH USERS
 */
export class LoadSearch implements Action {
  readonly type = LOAD_SEARCH;
  /*constructor(public payload: { list_id: number }) {};*/
}

export class LoadSearchSuccess implements Action {
  readonly type = LOAD_SEARCH_SUCCESS;
  constructor(public payload: { writers: User[], reviewers: User[] }) {}
}

export class LoadSearchError implements Action {
  readonly type = LOAD_SEARCH_ERROR;
}

/*
* ------------------------------------------------------------------------------> SEARCH NEWS ACTION
* */
export class SearchNewsAction implements Action {
  readonly type = SEARCH_NEWS_ACTION;
  constructor(public payload:
                {
                  perPage?: any, currentPage?: number,
                  term?: string,
                  writer?: number,
                  reviewer?: number,
                  date_enabled?: boolean,
                  start_date?: string,
                  end_date?: string
                }) {}
}

export class SearchNewsActionSuccess implements Action {
  readonly type = SEARCH_NEWS_ACTION_SUCCESS;
  constructor(public payload: any) {}
}

export class SearchNewsActionError implements Action {
  readonly type = SEARCH_NEWS_ACTION_ERROR;
}




export class LogoutEditorState implements Action {
  readonly type = LOGOUT_EDITOR_STATE;
}

export class UpdateReviewNewsLoaded implements Action {
  readonly type = CHANGE_REVIEW_NEWS_LOADED;
  constructor(public payload: { isLoaded: boolean }) {}
}

export type All = GetReviewNews | GetReviewNewsSuccess | GetReviewNewsError |
                  ApproveNews | ApproveNewsSuccess | ApproveNewsError |
                  RejectNews | RejectNewsSuccess | RejectNewsError |
                  LoadSearch | LoadSearchSuccess | LoadSearchError;
