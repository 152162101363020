import { Action } from '@ngrx/store';
import {NewsParticular} from "../../news/models/news";
import {TeleprompterSettings} from "../../store/app.state";

export const GET_APP_SETTINGS = '[Settings] LOAD_APP_SETTINGS';
export const GET_APP_SETTINGS_SUCCESS = '[Settings] LOAD_APP_SETTINGS_SUCCESS';
export const GET_APP_SETTINGS_ERROR = '[Settings] LOAD_APP_SETTINGS_ERROR';

export const SAVE_PARTICULARS = '[News] SAVE_PARTICULARS';
export const SAVE_PARTICULARS_SUCCESS = '[News] SAVE_PARTICULARS_SUCCESS';
export const SAVE_PARTICULARS_ERROR = '[News] SAVE_PARTICULARS_ERROR';

export const GET_PROMPTER_SETTINGS = '[Teleprompter] GET_PROMPTER_SETTINGS';
export const GET_PROMPTER_SETTINGS_SUCCESS = '[Teleprompter] GET_PROMPTER_SETTINGS_SUCCESS';
export const GET_PROMPTER_SETTINGS_ERROR = '[Teleprompter] GET_PROMPTER_SETTINGS_ERROR';

export const UPDATE_PROMPTER_SETTINGS = '[Teleprompter] UPDATE_PROMPTER_SETTINGS';
export const UPDATE_PROMPTER_SETTINGS_SUCCESS = '[Teleprompter] UPDATE_PROMPTER_SETTINGS_SUCCESS';
export const UPDATE_PROMPTER_SETTINGS_ERROR = '[Teleprompter] UPDATE_PROMPTER_SETTINGS_ERROR';

/** -------------------------------------------------->MY NEWS LOADING ACTIONS */
export class GetAppSettings implements Action {
  readonly type = GET_APP_SETTINGS;
}

export class GetAppSettingsSuccess implements Action {
  readonly type = GET_APP_SETTINGS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetAppSettingsError implements Action {
  readonly type = GET_APP_SETTINGS_ERROR;
  constructor(public payload: any) {}
}

/**
 * -------------------------------------------------->SAVE SEPARATE NEWS PARTICULARS
 */
export class SaveParticulars implements Action {
  readonly type = SAVE_PARTICULARS;
  constructor(public payload: NewsParticular[]) {}
}

export class SaveParticularsSuccess implements Action {
  readonly type = SAVE_PARTICULARS_SUCCESS;
  /*constructor(public payload: NewsParticular[]) {}*/
}

export class SaveParticularsError implements Action {
  readonly type = SAVE_PARTICULARS_ERROR;
}


/** --------------------------------------------------> LOADING PROMPTER SETTINGS */
export class GetPrompterSettings implements Action {
  readonly type = GET_PROMPTER_SETTINGS;
}

export class GetPrompterSettingsSuccess implements Action {
  readonly type = GET_PROMPTER_SETTINGS_SUCCESS;
  constructor(public payload: TeleprompterSettings) {}
}

export class GetPrompterSettingsError implements Action {
  readonly type = GET_PROMPTER_SETTINGS_ERROR;
  constructor(public payload: any) {}
}


/** --------------------------------------------------> UPDATING PROMPTER SETTINGS */
export class UpdatePrompterSettings implements Action {
  readonly type = UPDATE_PROMPTER_SETTINGS;
  constructor(public payload: TeleprompterSettings) {}
}

export class UpdatePrompterSettingsSuccess implements Action {
  readonly type = UPDATE_PROMPTER_SETTINGS_SUCCESS;
  /*constructor(public payload: TeleprompterSettings) {}*/
}

export class UpdatePrompterSettingsError implements Action {
  readonly type = UPDATE_PROMPTER_SETTINGS_ERROR;
  constructor(public payload: any) {}
}

export type All = GetAppSettings | GetAppSettingsSuccess | GetAppSettingsError
  | GetPrompterSettings | GetPrompterSettingsSuccess | GetPrompterSettingsError
  | SaveParticulars | SaveParticularsSuccess | SaveParticularsError
  | UpdatePrompterSettings | UpdatePrompterSettingsSuccess | UpdatePrompterSettingsError;
