import { Observable ,  BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { SessionService } from './session.service';
import { AuthService } from './auth.service';

import { map, filter, tap ,  catchError, finalize } from 'rxjs/operators';





import * as userReducer from '../../authentication/store/user.reducer';
import * as userActions from '../../authentication/store/user.actions';
import { UserState } from '../../store/app.state';
import { Store } from '@ngrx/store';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
      private sessonService: SessionService,
      private authService: AuthService,
      public snackBar: MatSnackBar,
      private store: Store<any>,
    ) {}

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
      return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      return next.handle(this.addToken(request, this.sessonService.getSession('dnms_token')))
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            // console.log(`Request for ${request.urlWithParams} with method ${request.method}`);
            if (event.status === 200) { // OK
              // this.snackBar.open(event.statusText, 'dismiss', {duration: 1000});
            }else if (event.status === 201) { // created
              this.snackBar.open(event.statusText, 'dismiss', {duration: 1000});
            }else if (event.status === 202) { // accepted
              this.snackBar.open(event.statusText, 'dismiss', {duration: 1000});
            }else if (event.status === 204) { // no content (delete)
              this.snackBar.open(event.statusText, 'dismiss', {duration: 1000});
            }
          }
        }, error => {
          // console.error('NICE ERROR', error);
          switch (error.status) {
            case 400:
                return this.handle400Error(error);
            case 401:
              return this.handle401Error(request, next);
            case 403:
              return this.snackBar.open(error.error, 'dismiss');
            case 500:
              return this.snackBar.open('Server Error!', 'dismiss');
          }
        })
      );
      /*try {

        return next.handle(this.addToken(request, this.sessonService.getSession('dnms_token')));

      } catch (error) {
        if (error instanceof HttpErrorResponse) {

          switch ((<HttpErrorResponse>error).status) {
              case 400:
                  // return this.handle400Error(error);
              case 401:
                  this.handle401Error(request, next);
          }
        } else {
            return Observable.throw(error);
        }
      }*/
    }



    handle401Error(req: HttpRequest<any>, next: HttpHandler) {

    // logged_inthis.store.dispatch(new User401());
      console.log('signing out from interceptor');
      this.store.dispatch(new userActions.User401());
      // return this.authService.doSignOut();

      /*if (!this.isRefreshingToken) {
        console.log('handling 401');
          this.isRefreshingToken = true;

          // Reset here so that the following requests wait until the token
          // comes back from the refreshToken call.
          this.tokenSubject.next(null);

          return this.authService.refreshToken(this.sessonService.getSession('dnms_token'))
              .map((newToken: string) => {
                  if (newToken) {
                      this.tokenSubject.next(newToken);
                      console.log(newToken);
                      return next.handle(this.addToken(req, newToken));
                  }

                  // If we don't get a new token, we are in trouble so logout.
                  return this.authService.doSignOut();
              })
              .subscribe(error => {
                  // If there is an exception calling 'refreshToken', bad news so logout.
                  return this.authService.doSignOut();
              })
              .finally(() => {
                  this.isRefreshingToken = false;
              });
      } else {
          return this.tokenSubject
              .filter(token => token != null)
              // .take(1)
              .map(token => {
                  return next.handle(this.addToken(req, token));
              });
      }*/
  }

    handle400Error(error) {
      this.store.dispatch(new userActions.User401());
        // if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
        //     // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
        //     return this.authService.doSignOut();
        // }

        // return Observable.throw(error);
    }
}
/*
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
  constructor(private status: HTTPStatus) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        console.log(event)
        return event;
      }),
      catchError(error => {
        return Observable.throw(error);
      }),
      finalize(() => {
        this.status.setHttpStatus(false);
      })
    );
  }
}
*/
