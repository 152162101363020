<!-- ============================================================== -->
<!-- Display login button if user is not logged in -->
<!-- ============================================================== -->
<button mat-icon-button alt="log in" class="m-r-5" *ngIf="!logged_in" [routerLink]="['/authentication/login/']">
  <mat-icon style="color:#ffffff">account_circle</mat-icon>
</button>

<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <button [matMenuTriggerFor]="notification" mat-icon-button class="m-r-10">
    <mat-icon>message</mat-icon>
    <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button>
<mat-menu #notification="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">Notifications</div>
            </li>
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">

                        <a href="#" *ngFor="let notification of notifications">
                            <div class="round {{notification.round}}">
                                <i class="{{notification.icon}}"></i>
                            </div>
                            <div class="mail-content">
                                <h5>{{notification.title}}</h5>
                                <span class="mail-desc">{{notification.subject}}</span>
                                <span class="time">{{notification.time}}</span>
                            </div>
                        </a>

                    </perfect-scrollbar>
                </div>
            </li>

        </ul>
    </div>
</mat-menu> -->
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="message" mat-icon-button class="m-r-10">
    <!-- <mat-icon>email</mat-icon> -->
    <!-- <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div> -->
</button>
<mat-menu #message="matMenu" class="mymessage">
    <div class="mailbox">
        <ul>
            <!-- <li>
                <div class="drop-title">You have 4 new Mymessages</div>
            </li> -->
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">

                        <a href="#" *ngFor="let mymessage of chatArray">
                            <div class="user-img">
                                <img src="{{storagePath}}profiles/user/{{mymessage.userimage}}" alt="user" class="img-circle" width="40">
                                <!-- <span class="profile-status {{mymessage.status}} pull-right"></span> -->
                            </div>
                            <div class="mail-content">
                                <h5>{{mymessage.username}}</h5>
                                <span class="mail-desc">{{mymessage.text}}</span>
                                <!-- <span class="time">{{mymessage.time}}</span> -->
                            </div>
                        </a>
                    </perfect-scrollbar>
                </div>
            </li>
        </ul>
    </div>
</mat-menu>
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5" *ngIf="logged_in">
  <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!-- <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button> -->
    <button mat-menu-item [routerLink]="['/user/profile']">
        <mat-icon>account_box</mat-icon> Profile </button>
    <!-- <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications </button> -->
    <button mat-menu-item (click)="logOut()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu>
