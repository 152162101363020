import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription ,  Subject } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { RouteRoleService } from './route-role.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnDestroy {
  loggedInSub: Subscription;
  destroySubject$: Subject<void> = new Subject();

  constructor(
      private auth: AuthService,
      private router: Router,
      private role: RouteRoleService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.loggedInSub = this.auth.loggedIn$.pipe(takeUntil(this.destroySubject$)).subscribe(
        loggedIn => {
          if (!loggedIn ) {
            this.router.navigate(['/authentication/login']);
          }
          this.role.initialize();
        }
    );

    return true;
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
