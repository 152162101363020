import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Ticker, TickersNews} from '../../../news/models/news';

import { Store } from '@ngrx/store';
import * as newsActions from '../../../news/store/news.actions';

@Component({
  selector: 'app-add-ticker-news-dialog',
  templateUrl: './add-ticker-news-dialog.component.html'
})
export class AddTickerNewsDialogComponent implements OnInit {
  onAdd = new EventEmitter();

  tickerNews = new TickersNews();
  @Input() public dhivehiEnabled = true;

  constructor(
    public dialogRef: MatDialogRef<AddTickerNewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Ticker,
    private store: Store<any>,
  ) { }

  ngOnInit() {
    this.tickerNews.language.language_code = 'Dhv';
    this.tickerNews.ticker_id = this.data.id;
    this.tickerNews.enabled   = 0; // ticker news are disabled when added
  }


  changeLanguage() {
    if (this.dhivehiEnabled) {
      this.tickerNews.language.language_code = 'Dhv';
    } else {
      this.tickerNews.language.language_code = 'Eng';
    }
  }

  closeDialog(confirmation: boolean) {
    this.dialogRef.close(confirmation);
  }

  doAdd(id?: number) {
    this.closeDialog(true);
    // this.onAdd.emit(this.tickerNews);
    this.store.dispatch(new newsActions.AddTickerNews(this.tickerNews));
    this.onAdd.emit();
  }

}
