<!-- <h3 mat-dialog-title>Add News to {{data.name}}</h3> -->

<mat-toolbar color="secondary" class="no-shadow">
  <mat-toolbar-row>
    <span fxFlex fxShow="false" fxShow.gt-xs>News Information</span>
    <div fxShow="false" fxShow.gt-xs>
      <!--<mat-slide-toggle labelPosition="after" color="secondary" [(ngModel)]="dhivehiEnabled" (ngModelChange)="changeLanguage()">{{tickerNews.language.language_code}}</mat-slide-toggle>-->
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div mat-dialog-content>

  <div style="margin-top:20px;" fxLayout="row" fxLayoutWrap="wrap">
    <div fxFlex.gt-sm="100" fxFlex="10A0" *ngFor="let particular of particulars">
      <mat-form-field appearance="outline">
        <mat-label>{{ particular.key }}</mat-label>
        <textarea matInput mat-autosize matAutosizeMinRows="1" #dhvInput required
                  [appDnrmsThaana]="true"
                  [(ngModel)]="particular.value"
                  name="dhvInput"></textarea>
      </mat-form-field>
    </div>
  </div>

   <!--<div fxLayout="row" fxLayoutWrap="wrap">
      <div fxFlex.gt-sm="100" fxFlex="100">
          <code>{{particulars|json}}</code>
      </div>
  </div>-->

</div>
<!-- actions column -->
<div align="end" mat-dialog-actions align="end">
  <button mat-button color="basic" (click)="closeDialog(true)">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>

  <!-- <button mat-button color="basic" *ngIf="data.newsstatus.status != 'published' && data.newsstatus.status != 'deleted'" routerLink="/news/{{data.id}}" (click)="closeDialog(true)">Edit</button> -->
</div>
