import {
  Directive,
  ElementRef,
  AfterViewInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Renderer2
  } from '@angular/core';
import { NgModel, NgControl } from '@angular/forms';

@Directive({
  selector: '[appDnrmsThaana]',
  // providers: [NgModel],
    host: {
      '(input)': 'translate($event)',
  }
})
// '[value]': 'appDnrmsThaana',
// '(ngModelChange)': 'keyupEvent()'
export class ThaanaDirective implements AfterViewInit, OnInit {

  @Input() appDnrmsThaana = true;
  new_text: string;
  firstChar = true;
  selStart: number;
  selEnd: number;
  model_value: string;

  @Output() ngModelChange = new EventEmitter();

  _transFrom  = 'qwertyuiop[]\\asdfghjkl;\'zxcvbnm,./QWERTYUIOP{}|ASDFGHJKL:\"ZXCVBNM<>?()';
  _transToKbd = {
    'phonetic': 'ްއެރތޔުިޮޕ][\\ަސދފގހޖކލ؛\'ޒ×ޗވބނމ،./ޤޢޭޜޓޠޫީޯ÷}{|ާށޑﷲޣޙޛޚޅ:\"ޡޘޝޥޞޏޟ><؟)(',
    'phonetic-hh': 'ޤަެރތޔުިޮޕ][\\އސދފގހޖކލ؛\'ޒޝްވބނމ،./ﷲާޭޜޓޠޫީޯޕ}{|ޢށޑޟޣޙޛޚޅ:\"ޡޘޗޥޞޏމ><؟)(',
    'typewriter': 'ޫޮާީޭގރމތހލ[]ިުްަެވއނކފﷲޒޑސޔޅދބށޓޯ×’“/:ޤޜޣޠޙ÷{}<>.،\"ޥޢޘޚޡ؛ޖޕޏޗޟޛޝ\\ޞ؟)('
  };

  constructor(
    private elem: ElementRef,
    private _model: NgModel,
    private control: NgControl,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.selStart = this.elem.nativeElement.selectionStart;
    this.selEnd   = this.elem.nativeElement.selectionEnd;
  }

  ngAfterViewInit(): void {
    this.applyDhivehiStyles(this.appDnrmsThaana);
  }

 applyDhivehiStyles(apply: boolean) {
   if (apply) {
    this.elem.nativeElement.style.color = '#2d2b2d';
    this.elem.nativeElement.style.fontSize = '20px';
    this.elem.nativeElement.style.textAlign = 'right';
    this.elem.nativeElement.style.direction = 'rtl';
    this.elem.nativeElement.style.lineHeight = '38px';
    this.elem.nativeElement.style.fontFamily = 'MV Faseyha';
    this.renderer.addClass(this.elem.nativeElement, 'dhv');
    // this.elem.nativeElement.style.unicodeBidi = 'bidi-override';
  }else {
    this.elem.nativeElement.style.color = '#2d2b2d';
    this.elem.nativeElement.style.fontSize = '16px';
    this.elem.nativeElement.style.textAlign = 'left';
    this.elem.nativeElement.style.direction = 'ltr';
    this.elem.nativeElement.style.unicodeBidi = '';
    this.elem.nativeElement.style.lineHeight = '28px';
    this.elem.nativeElement.style.fontFamily = 'Poppins';
    this.renderer.removeClass(this.elem.nativeElement, 'dhv');
  }
 }

//  @HostListener('ngModelChange', ['$event'])
//   onEvent(event) {

//     if (!this.appDnrmsThaana) {
//       this.applyDhivehiStyles(false);
//       return;
//     } else {
//       this.applyDhivehiStyles(true);
//     }

//     const nElm = this.elem.nativeElement;

//     const _inputValue = nElm.value; // nElm.value;
//     let _key = event.key;
//     let _keyChar = '';
//     let ctrl: any;


//     if (_inputValue.length === 0) {
//       return true;
//     } else if (_inputValue.length <= 1) {
//       // _keyChar = this.elem.nativeElement.value;
//       _keyChar = _inputValue;
//     } else {
//       // _keyChar = this.elem.nativeElement.value.substr(this.elem.nativeElement.selectionStart - 1, 1);
//       // _keyChar = _inputValue.substr(nElm.selectionStart - 1, 1);
//       _keyChar = _inputValue.substr(_inputValue.length - 1, 1);
//     }

//     if (event.which == null) {
//       // IE:
//       _key = event.key;
//     }else if (event.which > 0) {
//       // Non-IE printable chars:
//       _key = event.which;

//       // Handle special keys misfire in Opera
//       if ([45, 46, 35, 36].indexOf(event.key) === 1) {
//         return true;
//       }
//     }else {
//       // Ignore special keys
//       return true;
//     }
//     // Check for CTRL modifier key
//     if (event.modifier) {
//       ctrl = event.modifiers && event.CONTROL_MASK;
//     }else if (typeof(event.ctrlKey) !== 'undefined') {
//       ctrl = event.ctrlKey;
//     }
//     const selOld: number = nElm.selectionEnd;

//     try {
//       const transIndex = this._transFrom.indexOf(_keyChar);

//       if (transIndex === -1 || ctrl) {
//         return true;
//       }

//       const tChar = this._transToKbd['phonetic'].substr(transIndex, 1);

//       if (this.firstChar) {
//         // this.new_text  = tChar;
//         this.firstChar = false;

//         this.new_text = _inputValue.substring(0, nElm.selectionStart - 1)
//                       + tChar
//                       + _inputValue.substring(nElm.selectionEnd, _inputValue.length);
//       } else {
//         this.new_text = _inputValue.substring(0, nElm.selectionStart - 1)
//                       + tChar
//                       + _inputValue.substring(nElm.selectionEnd, _inputValue.length);

//       }

//         this.elem.nativeElement.value = this.new_text;
//         // this._model.valueAccessor.writeValue(this.new_text);
//         this.ngModelChange.emit(this.new_text);

//         // console.log(this.new_text);
//         // console.log(nElm.value);
//         // this.render.setElementProperty(this.elem.nativeElement, 'value', this.new_text);
//       }catch (error) {
//         console.error(error);
//       }
//       nElm.setSelectionRange(selOld, selOld);
//       nElm.focus();
//   }

// @HostListener('focus', ['$event'])
//   onFocus(event) {

    // const nElm = this.elem.nativeElement;
    // const _inputValue = nElm.value;

    // nElm.setSelectionRange(nElm.selectionStart, nElm.selectionStart);
    // nElm.focus();

    // this.model_value = _inputValue;
    // this.selStart = nElm.selectionStart;
    // this.selEnd   = nElm.selectionEnd;
//  }


  translate(event) {

    if (!this.appDnrmsThaana) {
      this.applyDhivehiStyles(false);
      return;
    } else {
      this.applyDhivehiStyles(true);
    }

    const nElm = this.elem.nativeElement;

    const _inputValue = nElm.value; // nElm.value;
    let _key = event.key;
    let _keyChar = '';
    let ctrl: any;

    if (_inputValue.length === 0) {
      return true;
    } else if (_inputValue.length <= 1) {
      // _keyChar = this.elem.nativeElement.value;
      _keyChar = _inputValue;
    } else {
      // _keyChar = this.elem.nativeElement.value.substr(this.elem.nativeElement.selectionStart - 1, 1);
      _keyChar = _inputValue.substr(nElm.selectionStart - 1, 1);
      // _keyChar = _inputValue.substr(_inputValue.length - 1, 1);
    }

    if (event.which == null) {
      // IE:
      _key = event.key;
    }else if (event.which > 0) {
      // Non-IE printable chars:
      _key = event.which;

      // Handle special keys misfire in Opera
      if ([45, 46, 35, 36].indexOf(event.key) === 1) {
        return true;
      }
    }else {
      // Ignore special keys
      // return true;
    }

    // Check for CTRL modifier key
    if (event.modifier) {
      ctrl = event.modifiers && event.CONTROL_MASK;
    }else if (typeof(event.ctrlKey) !== 'undefined') {
      ctrl = event.ctrlKey;
    }

    const selOld: number = nElm.selectionEnd;
    this.selEnd = nElm.selectionEnd;
    this.selStart = nElm.selectionStart;

    try {
      const transIndex = this._transFrom.indexOf(_keyChar);

      if (transIndex === -1 || ctrl) {
        return true;
      }

      const tChar = this._transToKbd['phonetic'].substr(transIndex, 1);

      if (this.firstChar) {
        // this.new_text  = tChar;
        this.firstChar = false;

        this.new_text = _inputValue.substring(0, nElm.selectionStart - 1)
                      + tChar
                      + _inputValue.substring(nElm.selectionEnd, _inputValue.length);
      } else {
        this.new_text = _inputValue.substring(0, nElm.selectionStart - 1)
                      + tChar
                      + _inputValue.substring(nElm.selectionEnd, _inputValue.length);

      }

        this.ngModelChange.emit(this.new_text);
        this._model.valueAccessor.writeValue(this.new_text);

    }catch (error) {
      console.error(error);
    }

    this.setCursor();
  }


  setCursor() {
    // console.log('SELECTION: '+this.selStart+'||||'+this.selEnd)
    this.elem.nativeElement.focus();
    this.elem.nativeElement.setSelectionRange(this.selStart, this.selEnd);
  }

}
