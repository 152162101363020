import { Store } from '@ngrx/store';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { SessionService } from './session.service';
import { Injectable, OnInit, OnDestroy } from '@angular/core';

import * as userActions from '../../authentication/store/user.actions';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteRoleService implements OnInit, OnDestroy {

  routeObserver$: any;
  roleSubscription$: any;

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>
  ) { }

  ngOnInit() {
  }

  initialize() {
    this.routeObserver$ = this.router.events
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(event => {
        if (event instanceof RoutesRecognized) {
          this.guardRoute(event);
        } /*else if (event instanceof NavigationEnd) {
          this.checkRole(event);
        }*/
      });
  }

  private guardRoute(event: RoutesRecognized): void {
    if (this.isPublic(event)) {
      return;
    }

    if (!this.callCheckToken(event)) {
      return;
    }
  }

  private callCheckToken(event: RoutesRecognized) {
    // const token_date = new Date(this.session.getSession('expiry'));
    // const now_date   = new Date().getTime();

    // if (token_date.getTime() < now_date) {
    //   this.store.dispatch(new userActions.UserLogout());
    //   //this.routeObserver$.unsubscribe();
    //   return false;
    // }

    return true;
  }

  private isPublic(event: RoutesRecognized) {
    return event.state.root.firstChild.data.isPublic;
  }

  private checkRole(event) {
    let roles_  = false;

    const sRoutes = event.url.split('/');
    this.roleSubscription$ = this.store.select(RootStore => RootStore.UserState.user.roles)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(roles => {

        if (sRoutes.includes('authentication') || sRoutes.includes('dashboard')) {
          roles_ = true;
        }

        if (roles) {
          Object.keys(roles).forEach(function(key) {
            Object.keys(roles[key].permissions).forEach(function(role_key) {
              // console.log(sRoutes);
              // console.log('PERM= ' + roles[key].permissions[role_key].permission);
              if (sRoutes.includes(roles[key].permissions[role_key].permission)) {
                roles_ = true;
              }
            });
        });
        }
      });

    // user have permission to this route
    if (roles_) {
      return;
    }
    // the user does not have the required permission
    if (!roles_) {
      this.router.navigate(['/authentication/401']);
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    // this.routeObserver$.unsubscribe();
    // this.roleSubscription$.unsubscribe();
  }

}
