
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, switchMap, map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import {Effect, Actions, ofType} from '@ngrx/effects';





import { AuthService } from '../../shared/services/auth.service';
import * as UserActions from './user.actions';
import * as NewsActions from '../../news/store/news.actions';
import * as SettingsActions from '../../settings/store/settings.actions';
import * as EditorActions from '../../editor/store/news-editor.actions';
import { SessionService } from '../../shared/services/session.service';
import { User } from '../../shared/models/user';

@Injectable()
export class UserEffects {

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private sessionService: SessionService,
    private store: Store<any>,
    private router: Router
  ) {}

  @Effect()
  LogIn: Observable<any> = this.actions.
    pipe(
      ofType(UserActions.USER_LOGIN),
      map((action: UserActions.UserLogin) => action.payload),
      switchMap(payload => {
        return this.authService.signIn(payload.email, payload.password).pipe(
          map((user: User) => {
            // console.log(user);
            this.authService.doSignIn(user);
            return new UserActions.UserLoginSuccess(user);
          }),
          catchError((error) => {
            console.log(error);
            return observableOf(new UserActions.UserLoginError());
          }),);
      }),
    );

  @Effect({ dispatch: false })
  LogInSuccess: Observable<any> = this.actions.
    pipe(
      ofType(UserActions.USER_LOGIN_SUCCESS),
      map((action: UserActions.UserLoginSuccess) => action.payload),
      map((user: User) => {
        // console.log(user);
        if (this.sessionService.saveSession(user)) {
          // this.router.navigate(['/dashboard/default']);
          // this._location.back();
        }
        // localStorage.setItem('token', user.user.token.token);

      }),
    );

  @Effect()
  GetUserSettings: Observable<any> = this.actions.
    pipe(
      ofType(UserActions.USER_GET_SETTINGS),
      map((action: UserActions.GetUserSettings) => action),
      switchMap(payload => {
        return this.sessionService.getUserSetting()
          .pipe(
            map((user: User) => {
              this.store.dispatch(new SettingsActions.GetAppSettings());
              return new UserActions.GetUserSettingsSuccess(user);
            }),
            catchError((error) => {
              console.log(error);
              return observableOf(new UserActions.GetUserSettingsError());
            })
          )
      }),
    );

  @Effect()
  ServerGetUserSettings: Observable<any> = this.actions.
    pipe(
      ofType(UserActions.SERVER_USER_GET_SETTINGS),
      map((action: UserActions.ServerGetUserSettings) => action),
      switchMap(payload => {
        return this.sessionService.updateUserSettings().pipe(
          map((user: User) => {
            this.sessionService.saveSession(user);
            return new UserActions.ServerGetUserSettingsSuccess(user);
          }),
          catchError((error) => {
            console.log(error);
            return observableOf(new UserActions.ServerGetUserSettingsError());
          }),);
      }),
    );

  @Effect()
  UserLogout: Observable<any> = this.actions.
    pipe(
      ofType(UserActions.USER_LOGOUT),
      map((action: UserActions.UserLogout) => action),
      switchMap(payload => {
        this.store.dispatch(new NewsActions.LogoutNewsState());
        this.store.dispatch(new EditorActions.LogoutEditorState());
        this.authService.doSignOut();

        this.router.navigate(['/authentication/login']);
        // return Observable.of(new UserActions.UserLogoutSuccess(this.sessionService.getUserLogoutSetting()));
        return observableOf(new UserActions.UserLogoutSuccess());
      }),
    );

  UserLogoutSuccess: Observable<any> = this.actions.
    pipe(
      ofType(UserActions.USER_LOGOUT_SUCCESS),
      map((action: UserActions.UserLogoutSuccess) => {
        //location.reload();
        this.router.navigate(['/authentication/login']);
      })
    );

  @Effect()
  User401: Observable<any> = this.actions.
    pipe(
        ofType(UserActions.USER_401),
        switchMap(payload => {
          console.warn('401 user found!!');
          return observableOf(new UserActions.UserLogout());
        })
    );

}
