import {Actions, Effect, ofType} from '@ngrx/effects';

import * as SettingsActions from './settings.actions';
import {Injectable} from "@angular/core";
import {NewsService} from "../../news/services/news.service";
import {Observable, of} from "rxjs";
import {Action} from "@ngrx/store";
import * as UserActions from "../../authentication/store/user.actions";
import {catchError, map, mergeMap} from "rxjs/operators";
import {SettingsServiceService} from "../services/settings-service.service";
import * as NewsActions from "../../news/store/news.actions";
import * as TeleprompterActions from "../../teleprompter/store/teleprompter.actions";
import {TeleprompterService} from "../../teleprompter/services/teleprompter.service";

@Injectable()
export class SettingsEffects {

  constructor(
    private actions$: Actions,
    private settingsService: SettingsServiceService,
    private prompterService: TeleprompterService,
  ) {
  }

  @Effect()
  GetAppSettings$: Observable<Action> = this.actions$.
  pipe(
    ofType<SettingsActions.GetAppSettings>(SettingsActions.GET_APP_SETTINGS),
    mergeMap(action =>
      this.settingsService.getAppSettings().pipe(
        map((data) => {
          console.log(data)
          return new SettingsActions.GetAppSettingsSuccess(data);
        }),
        catchError((error) => {
          console.log(error)
          return of(new SettingsActions.GetAppSettingsError(error));
        }))
    )
  );

  // save news particulars
  @Effect()
  SaveNewsParticulars$: Observable<Action> = this.actions$.
  pipe(
    ofType<SettingsActions.SaveParticulars>(SettingsActions.SAVE_PARTICULARS),
    mergeMap(action =>
      this.settingsService.saveNewsParticulars(action.payload).pipe(
        map((data) => {
          return new SettingsActions.SaveParticularsSuccess();
        }),
        catchError((error) => {
          console.log("------------------------ERR")
          console.log(error)
          return of(new SettingsActions.SaveParticularsError())
        } ),)
    )
  );

  /* load teleprompter settings when get news lists succeed*/
  @Effect()
  GetSettings$: Observable<Action> = this.actions$.
  pipe(
    ofType<SettingsActions.GetAppSettingsSuccess>(SettingsActions.GET_APP_SETTINGS_SUCCESS),
    map(action => {
      return new SettingsActions.GetPrompterSettings();
    })
  );

  @Effect()
  GetPrompterSettings$: Observable<Action> = this.actions$.
  pipe(
    ofType<SettingsActions.GetPrompterSettings>(SettingsActions.GET_PROMPTER_SETTINGS),
    mergeMap(action =>
      this.prompterService.getSettings().pipe(
        map((data) => {
          console.log(data);
          return new SettingsActions.GetPrompterSettingsSuccess(data);
        }),
        catchError(() => of(new SettingsActions.GetPrompterSettingsError("Error Loading prompter Settings"))),)
    )
  );

  // save teleprompter settings
  @Effect()
  SaveTeleprompterSettings$: Observable<Action> = this.actions$.
  pipe(
    ofType<SettingsActions.UpdatePrompterSettings>(SettingsActions.UPDATE_PROMPTER_SETTINGS),
    mergeMap(action =>
      this.prompterService.updateSettings(action.payload).pipe(
        map((data) => {
          return new SettingsActions.UpdatePrompterSettingsSuccess();
        }),
        catchError((error) => {
          console.log("------------------------ERR:settings>effects>prompter")
          console.log(error)
          return of(new SettingsActions.UpdatePrompterSettingsError("settings>effects>prompter"))
        } ),)
    )
  );

  /*load the teleprompter settings when save settings succeeds*/
  @Effect()
  GetPrompterSettingsOnSave$: Observable<Action> = this.actions$.
  pipe(
    ofType<SettingsActions.UpdatePrompterSettingsSuccess>(SettingsActions.UPDATE_PROMPTER_SETTINGS_SUCCESS),
    mergeMap(action =>
      this.prompterService.getSettings().pipe(
        map((data) => {
          console.log(data);
          return new SettingsActions.GetPrompterSettingsSuccess(data);
        }),
        catchError(() => of(new SettingsActions.GetPrompterSettingsError("Error Loading prompter Settings"))),)
    )
  );

}
