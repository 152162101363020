import {User} from '../../shared/models/user';

export class News {
  public id?: number;
  public reference_id?: string;
  public news_name?: string;
  public heading?: string;
  public body?: string;
  public newsstatus_id?: number;
  public user_id?: number;
  public language?: Language;
  public newsbar?: NewsBar[];
  public tickernews?: TickerNews[];
  public newsstatus?: Newsstatus;
  public newsnotes?: NewNotes[];
  public draft?: boolean;
  public loading?: boolean;
  public list_news?: ListNews[];
  public particulars?: NewsParticular[];
  public user?: User;

  constructor() {
    this.reference_id = null;
    this.news_name = '';
    this.heading = '';
    this.body = '';
    this.newsstatus_id = 0;
    this.user_id = 0;
    this.language = new Language('', '');
    this.newsbar = [new NewsBar('', '')];
    this.draft = false;
  }

  static generateMockNews(): News {
    return new News();
  }

}

export class NewsBar {

  constructor(
    public title: string,
    public detail: string,
    public id?: number,
    public news_id?: number,
    public created_at?: string,
    public updated_a?: string
  ) { }

}

export class TickerNews {

  constructor(
    public news: string
  ) { }

}

export class Language {

  constructor(
    public language_code: string,
    public language?: string,
    public id?: number
  ) {}

}

export class Newsstatus {

  constructor(
    public id: string,
    public status: string,
    public detail: string,
  ) {}

}

export class NewNotes {

  constructor(
    public id: number,
    public news_id: number,
    public note: string,
  ) {}

}

export class NewsList {

  public id: number;
  public name: string;
  public created_at: string;
  public updated_at: string;
  public list_news: ListNews[];
  public particulars?: ListParticulars[];

  constructor() {
    this.id = null;
    this.name = '';
    this.created_at = '';
    this.updated_at = '';
    this.list_news = [new ListNews()];
    this.particulars = [new ListParticulars()]
  }

}

export class ListNews {

  public id: number;
  public news_list_id: number;
  public news_id: number;
  public created_at: string;
  public updated_at: string;
  public news: News;

  constructor() {
    this.id = null;
    this.news_list_id = null;
    this.news_id = null;
    this.created_at = null;
    this.updated_at = null;
    this.news = new News();
  }

}

export class ListParticulars {
  public id: number;
  public key: string;
  public value: string;
  public news_list_id: number;
  public user_id: number;
  public extra_attributes: any;

  constructor() {
    this.id = null;
    this.key = null;
    this.value = null;
    this.news_list_id = null;
    this.user_id = null;
    this.extra_attributes = null;
  }
}

export class ListOrder{
  list_id: number;
  news_id: number;
  order: number;

  constructor ( list_id, news_id, order ) {
    this.list_id  = list_id;
    this.news_id  = news_id;
    this.order    = order;
  }
}

export class Ticker {
  public id?: number;
  public name?: string;
  public color?: string;
  public separator?: string;
  public enabled?: number;
  public tickernews?: TickersNews[];
  public created_at?: string;
  public updated_at?: string;

  constructor() {
    this.name = '';
  }
}

export class TickersNews {
  public id?: number;
  public news: string;
  public name?: string;
  public language_id?: number;
  public enabled?: number;
  public ticker_id?: number;
  public language?: Language;
  public created_at?: string;
  public updated_at?: string;

  constructor() {
    this.news = '';
    this.language = new Language('', '');
  }
}



export class JournalistDashboard {
  public news_numbers?: JournalistDashboardNewsNumbers;
  public lists?: NewsList[];

  constructor() {
    this.news_numbers = new JournalistDashboardNewsNumbers();
    this.lists = [];
  }
}
export class JournalistDashboardNewsNumbers {
  public my: number;
  public reject: number;
  public approved: number;
  public in_review: number;

  constructor() {
    this.my = 0;
    this.reject = 0;
    this.approved = 0;
    this.in_review = 0;
  }
}

export class NewsParticular {
  public id?: number;
  public news_id?: number;
  public key?: string;
  public value?: string;

  constructor(id?: number, news_id?: number, key?: string, value?:string) {
    this.id = id ? id : null;
    this.news_id = news_id ? news_id : null;
    this.key = key ? key : '';
    this.value = value ? value : '';
  }
}
