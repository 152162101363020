import { ChangeDetectorRef, Component, NgZone, OnInit, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { SessionService } from '../../../shared/services/session.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';

import { Store } from '@ngrx/store';
import { UserState } from '../../../store/app.state';
import * as userActions from '../../../authentication/store/user.actions';
import { User } from '../../../shared/models/user';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  public username = 'Guest';
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  loggedInSub: Subscription;
  logged_in = false;

  public userData: User = new User();
  public storagePath: string;

  private _mobileQueryListener: () => void;
  status: boolean = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private session: SessionService,
    private auth: AuthService,
    private store: Store<UserState>,
    private store2: Store<any>,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // get user authentication status
    this.loggedInSub = this.auth.loggedIn$.subscribe( loggedIn => this.logged_in = loggedIn );
    this.username = session.getSession('name');
  }

  ngOnInit() {
    this.storagePath = environment.storageUrl;
    this.store2.select(store => store.UserState).subscribe(userdata => {
      this.userData = userdata.user;
    });
  }


  clickEvent() {
    this.status = !this.status;
  }

  subclickEvent() {
    this.status = true;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  checkRole(role: string) {
    return this.session.checkRole(role);
  }

  logOut(): void {
    this.store.dispatch(new userActions.UserLogout());
  }
}
