import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Action, Store} from "@ngrx/store";

import * as userAction from '../../authentication/store/user.actions';
import * as newsAction from '../../news/store/news.actions';
import * as editorAction from '../../editor/store/news-editor.actions';
import {Actions, ofType} from "@ngrx/effects";
import {ADD_NEWS_SUCCESS} from "../../news/store/news.actions";
import * as newsActions from "../../news/store/news.actions";

import * as prompterAction from "../../teleprompter/store/teleprompter.actions";
import * as prompterControlAction from "../../control/store/control.actions";
import * as settingsActions from "../../settings/store/settings.actions";

import {DsService} from "../../shared/services/ds.service";
import {User} from "../../shared/models/user";

@Injectable({
  providedIn: 'root'
})
export class DsPublishService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private dsService: DsService
  ) { }

  publishAction(action: Action, user: User, toUser?: User) {
    switch (action.type) {
      case newsAction.ADD_NEWS_SUCCESS:
        /*this.store.dispatch(new editorAction.GetReviewNews());*/
        this.sendLiveUpdate(null, user, action);
        console.log('------------------------------------------>>>>>DS PUBLISH::', action.type, action);
        break;

      /*case editorAction.APPROVE_NEWS_SUCCESS:*/

      case newsAction.GET_MY_NEWS_SUCCESS:
        /*this.store.dispatch(new editorAction.GetReviewNews());*/
        this.sendLiveUpdate(0, user, action)
        console.log('------------------------------------------>>>>>DS PUBLISH MY NEWS::',action.type, action);
        break;

      case settingsActions.UPDATE_PROMPTER_SETTINGS_SUCCESS:
        this.store.dispatch(new editorAction.GetReviewNews());
        this.prompterUpdates("SETTINGS_UPDATE", "UPDATE")
        console.log('------------------------------------------>>>>>DS PUBLISH TELE::',action.type, action);
        break;

      case prompterAction.RE_LOAD_PROMPTER_NEWS:
        this.store.dispatch(new editorAction.GetReviewNews());
        this.prompterUpdates("RE_LOAD_PROMPTER_NEWS", "RELOAD")
        console.log('------------------------------------------>>>>>DS PUBLISH TELE::',action.type, action);
        break;

      default:
        this.sendLiveUpdate(null, user, action)
        console.log('DEFAULT:------------------------------------------>>>>>DS PUBLISH Live::',action.type, action);
        break;
    }
  }

  sendLiveUpdate(toUser: number, fromUser: User, action: Action) {
    /*const recordName = 'chat/' + this.ds.dsInstance.getUid();*/
    // const recordName = 'chat/' + this.userData.id;
    const listName = 'live-updates/0';
    /*let recordList = this.dsService.getRecord(listName);*/

    /*if (toUser){
      const record = this.dsService.getRecord(listName+'/'+toUser);
      record.set({toUser, fromUser, action});
      // Update the chats list
      recordList.addEntry(listName+'/'+toUser);
    } else {*/
      const record = this.dsService.getRecord(listName);
      record.set({toUser, fromUser, action, date: new Date()});
      // Update the chats list

    const list = this.dsService.getList('live-updates');
    list.addEntry(listName);
    console.log('=x=x=x=x=x=x=x=x=x=x=x=x=x=xx=', list);
    /*}*/
  }

  prompterUpdates(action: string, data: any) {
    this.dsService.login();

    const listName = 'prompter-updates/0';

    const record = this.dsService.getRecord(listName);
    record.set({action: action, data: data, time: new Date()});

    const list = this.dsService.getList('prompter-updates');
    list.addEntry(listName);
  }
}
