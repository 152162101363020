import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import * as SettingsActions from './settings.actions';
import {AppSettingsState, NewsState} from '../../store/app.state';
import * as NewsActions from "../../news/store/news.actions";
import * as TeleprompterActions from "../../teleprompter/store/teleprompter.actions";

export const initialState: AppSettingsState = {
  newsParticulars: [],
  isLoaded: false,
  error: {
    status: false,
    message: ''
  },
  teleprompterSettings: {
    bodyFontSize: null,
    bodyLineHeight: null
  },
  ip: null,
  control_ips: []
};

export type Actions = SettingsActions.All;
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export function reducer(state = initialState, action: ActionWithPayload<any>): AppSettingsState {
  /*console.log(state, action);
  console.log('Settings REDUCER Call!');*/

  switch (action.type) {
    case SettingsActions.GET_APP_SETTINGS: {
      return {
        ...state,
        isLoaded: false
      };
    }

    case SettingsActions.GET_APP_SETTINGS_SUCCESS: {
      return {
        ...state,
        newsParticulars: action.payload.news_particulars,
        ip: action.payload.ip,
        control_ips: action.payload.control_ips,
        isLoaded: true
      };
    }

    case SettingsActions.GET_APP_SETTINGS_ERROR: {
      return {
        ...state,
        isLoaded: true,
        error: {
          status: true,
          message: 'Error retrieving app settings!'
        }
      };
    }

    /**
     * ---------------------------------------------------------------------> SAVE NEWS PARTICULARS
     */
    case SettingsActions.SAVE_PARTICULARS: {
      return {
        ...state,
        isLoaded: false
      };
    }
    case SettingsActions.SAVE_PARTICULARS_SUCCESS: {
      return {
        ...state,
        isLoaded: true
      };
    }
    case SettingsActions.SAVE_PARTICULARS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }


    /* --------------------- update teleprompter settings ------------------- */
    case SettingsActions.GET_PROMPTER_SETTINGS: {
      return {
        ...state,
        isLoaded: false,
      };
    }

    case SettingsActions.GET_PROMPTER_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        teleprompterSettings: action.payload
      };
    }

    case SettingsActions.GET_PROMPTER_SETTINGS_ERROR: {
      return {
        ...state,
        isLoaded: false,
        error: {
          status: true,
          message: action.payload
        }
      };
    }


    /* --------------------- update teleprompter settings ------------------- */
    case SettingsActions.UPDATE_PROMPTER_SETTINGS: {
      return {
        ...state,
        isLoaded: false,
      };
    }

    case SettingsActions.UPDATE_PROMPTER_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        /*teleprompterSettings: action.payload*/
      };
    }

    case SettingsActions.UPDATE_PROMPTER_SETTINGS_ERROR: {
      return {
        ...state,
        isLoaded: false,
        error: {
          status: true,
          message: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }

}
