import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { AppState } from './app.state';
import { environment } from '../../environments/environment';

import * as userAction from '../authentication/store/user.actions';

import * as settingsReducer from '../settings/store/settings.reducer';
import * as userReducer from '../authentication/store/user.reducer';
import * as newsReducer from '../news/store/news.reducer';
import * as reviewNewsReducer from '../editor/store/news-editor.reducer';
import * as teleprompterReducer from '../teleprompter/store/teleprompter.reducer';

export const reducers: ActionReducerMap<AppState> = {
  AppSettings: settingsReducer.reducer,
  UserState: userReducer.reducer,
  NewsState: newsReducer.reducer,
  ReviewNews: reviewNewsReducer.reducer,
  TeleprompterState: teleprompterReducer.reducer
};

export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function(state: AppState, action: any): AppState {
    /*console.log('state', state);
    console.log('action', action);*/


    // if (action.type === userAction.USER_LOGOUT_SUCCESS) {
    //   console.log('x-x ROOT REDUCER LOGOUT x-x');
    //   // state = { UserState: { user: { email: null, name: null, token: {token: null}, roles: null, roles_a: null } }, NewsState: {myNews: null, myNewsPageState: { currentPage: null, perPage: null, lastPages: null, totalResults: null }, trashNewsPageState: null, trashNews: null} };
    //   state = undefined;
    //   this.router.navigate(['/authentication/login']);
    // }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger]
  : [];
