import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import * as TeleprompterActions from './teleprompter.actions';
import {AppSettingsState, TeleprompterState} from "../../store/app.state";

export const initialState: TeleprompterState = {
  isLoaded: false,
  list: null,
  error: null
}

export type Actions = TeleprompterActions.All;
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export function reducer(state = initialState, action: ActionWithPayload<any>): TeleprompterState {
  switch (action.type) {

    case TeleprompterActions.LOAD_PROMPTER_NEWS: {
      return {
        ...state,
        isLoaded: false,
      }
    }

    case TeleprompterActions.LOAD_PROMPTER_NEWS_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        list: action.payload
      }
    }

    case TeleprompterActions.LOAD_PROMPTER_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: false,
        error: action.payload
      }
    }

    default: {
      return state;
    }
  }
}
