import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../shared/services/auth.service';

import * as userActions from '../../../authentication/store/user.actions';
import { UserState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { Router } from '../../../../../node_modules/@angular/router';

import { DsService } from '../../../shared/services/ds.service';
import { User } from '../../../shared/models/user';
import { environment } from '../../../../environments/environment';
declare var deepstream: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};
  loggedInSub: Subscription;
  logged_in = false;
  show_top_icons = false;
  public userData: User = new User();




  storagePath: string = null;
  title = 'deepChat';
  username;
  text: string = 'first chat record';
  chats;
  chatArray = [];


  constructor(
    private auth: AuthService,
    private store: Store<UserState>,
    private ds: DsService,
    private store2: Store<any>,
  ) {
    this.storagePath = environment.storageUrl;

    // get user authentication status
    this.loggedInSub = this.auth.loggedIn$.subscribe( loggedIn => this.logged_in = loggedIn );

    this.store2.select(store => store.UserState).subscribe(userdata => {
      this.userData = userdata.user;
      //this.chantInit();
    });
  }

  logOut(): void {
    // this.auth.doSignOut();
    this.store.dispatch(new userActions.UserLogout());

  }


    // chantInit(){
    //   this.username = this.userData.email;
    //   // Login without credentials
    //   this.ds.login(null, this.loginHandler);

    //   this.chats = this.ds.getList('chats');
    //   this.chats.on('entry-added', recordName => {
    //     this.ds.getRecord( recordName ).whenReady( record => {
    //       record.subscribe( (data) => {
    //         if(data.username && data.text) {
    //           // Update bindable property
    //           this.chatArray.push(data);
    //           console.log(this.chatArray)
    //         }
    //       }, true );
    //     });
    //   })


    //   const recordName = 'chat/' + this.ds.dsInstance.getUid();

    //   const chatRecord = this.ds.getRecord(recordName);
    //   chatRecord.set({uid: this.userData.id ,username: this.username, text: this.text, userimage: this.userData.profile.profilepic});
    //   this.text = '';
    //   // Update the chats list
    //   this.chats.addEntry(recordName);
    // }


    // loginHandler(success, data) {
    //   console.log('logged in', success, data);
    // }

}











// This is for Notifications
  // notifications: Object[] = [
  //   {
  //     round: 'round-danger',
  //     icon: 'ti-link',
  //     title: 'Launch Admin',
  //     subject: 'Just see the my new admin!',
  //     time: '9:30 AM'
  //   },
  //   {
  //     round: 'round-success',
  //     icon: 'ti-calendar',
  //     title: 'Event today',
  //     subject: 'Just a reminder that you have event',
  //     time: '9:10 AM'
  //   },
  //   {
  //     round: 'round-info',
  //     icon: 'ti-settings',
  //     title: 'Settings',
  //     subject: 'You can customize this template as you want',
  //     time: '9:08 AM'
  //   },
  //   {
  //     round: 'round-primary',
  //     icon: 'ti-user',
  //     title: 'Pavan kumar',
  //     subject: 'Just see the my admin!',
  //     time: '9:00 AM'
  //   }
  // ];

  // This is for Mymessages
  // mymessages: Object[] = [
  //   {
  //     useravatar: 'assets/images/users/1.jpg',
  //     status: 'online',
  //     from: 'Pavan kumar',
  //     subject: 'Just see the my admin!',
  //     time: '9:30 AM'
  //   },
  //   {
  //     useravatar: 'assets/images/users/2.jpg',
  //     status: 'busy',
  //     from: 'Sonu Nigam',
  //     subject: 'I have sung a song! See you at',
  //     time: '9:10 AM'
  //   },
  //   {
  //     useravatar: 'assets/images/users/2.jpg',
  //     status: 'away',
  //     from: 'Arijit Sinh',
  //     subject: 'I am a singer!',
  //     time: '9:08 AM'
  //   },
  //   {
  //     useravatar: 'assets/images/users/4.jpg',
  //     status: 'offline',
  //     from: 'Pavan kumar',
  //     subject: 'Just see the my admin!',
  //     time: '9:00 AM'
  //   }
  // ];
