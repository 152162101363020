import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ThaanaDirective } from './directives/thaana.directive';
import { NewsParticularDialogComponent } from './component/news-particular-dialog/news-particular-dialog.component';
import {DemoMaterialModule} from "../demo-material-module";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ThaanaDirective,
    NewsParticularDialogComponent,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ThaanaDirective
  ],
  imports: [
    DemoMaterialModule,
    FormsModule,
    CommonModule
  ],
  providers: [MenuItems]
})
export class SharedModule { }
