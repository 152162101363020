import { User } from '../models/user';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
/*import { NOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR } from '@angular/core/src/view/provider';*/

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_URL = environment.apiUrl;

  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean = false;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

  constructor(
      private session: SessionService,
      private http: HttpClient
  ) {
    if (this.session.getSession('dnms_token')) {
      this.loggedIn$.next(true);
      this.loggedIn = true;
    }
  }

  public isSignedIn() {
    return !!this.session.getSession('dnms_token');
  }

  public doSignOut() {
    console.log('sign out');
    this.loggedIn$.next(false);
    this.loggedIn = false;
    this.session.destroy();
  }

  public signIn(email: string, password: string) {
    return this.http.post<any>(this.API_URL + 'authenticate/', { email, password });
  }

  // public doSignIn(accessToken: string, name: string, email: string, roles: string, roles_all: any[]) {
  public doSignIn(loginUser: User) {
    if ((!loginUser.token) || (!loginUser.name)) {
      return;
    }

    this.loggedIn$.next(true);
    this.loggedIn = true;

    this.session.saveSession(loginUser);
  }

  public refreshToken(token) {
    /**
     *    token refresh is not working from api
     *     //TODO need to fix token refreshing from API
      */
    // const headers = new HttpHeaders({'Authorization': 'Bearer ' + token});

    /*const ttoken = '{token: \'' + token + '\'}';
    const post_token = JSON.parse(JSON.stringify(ttoken));
    console.log(post_token);*/

    return this.http.post<any>(this.API_URL + 'refresh/?token=' + token , null);
  }

  public getUserSettings() {
    return this.session.getUserSetting;
  }

}
