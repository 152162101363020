import { News } from '../../news/models/news';
import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import * as EditorActions from './news-editor.actions';
import {ReviewNewsState, SearchNews} from '../../store/app.state';
import * as NewsActions from "../../news/store/news.actions";
import {getNewsState} from "../../news/store/news.reducer";





export const initialState: ReviewNewsState = {
    news: [],
    isLoaded: false,
    // pageState: {currentPage: null, perPage: null, lastPages: null, totalResults: null},
    lists: [],
    isListLoaded: false,
    selectedList: null,
    searchNews: {
      search_status: 'init',
      writers: [],
      reviewers: [],
      results: [],
    }
  };

export type Actions = EditorActions.All;
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export function reducer(state = initialState, action: ActionWithPayload<any>): ReviewNewsState {
  /*console.log(state, action);*/

  switch (action.type) {

    /**
     * --------------------------------------------------------------------------------------> REVIEW NEWS
     */
    case EditorActions.GET_REVIEW_NEWS: {
      return {
        ...state,
        news: [],
        isLoaded: false
      };
    }

    case EditorActions.GET_REVIEW_NEWS_SUCCESS: {
      return {
        ...state,
        news: action.payload,
        isLoaded: true
      };
    }

    /**
     * --------------------------------------------------------------------------------------> APPROVE NEWS
     */
    case EditorActions.APPROVE_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }

    case EditorActions.APPROVE_NEWS_SUCCESS: {
      return {
        ...state,
        news: [
         ...(state.news.filter( (news) => news.id !== action.payload.id )),
        ],
        isLoaded: true
      };
    }

    case EditorActions.APPROVE_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * --------------------------------------------------------------------------------------> REJECT NEWS
     */
    case EditorActions.REJECT_NEWS: {
      return {
        ...state,
        isLoaded: false
      };
    }

    case EditorActions.REJECT_NEWS_SUCCESS: {
      return {
        ...state,
        news: [
         ...(state.news.filter( (news) => news.id !== action.payload.news_id )),
        ],
        isLoaded: true
      };
    }

    case EditorActions.REJECT_NEWS_ERROR: {
      return {
        ...state,
        isLoaded: true
      };
    }

    /**
     * ---------------------------------------------------------------------------------------------------------
     * --------------------------------------------------------------------------------------> NEWS LIST ACTIONS
     * ---------------------------------------------------------------------------------------------------------
     */
    /**
     * --------------------------------------------------------------------------------------> GET LIST
     */
    case EditorActions.GET_LISTS: {
      return {
        ...state,
        isListLoaded: false
      }
    }

    case EditorActions.GET_LISTS_SUCCESS: {
      return {
        ...state,
        lists: action.payload,
        isListLoaded: true
      }
    }

    case EditorActions.GET_LISTS_ERROR: {
      return {
        ...state,
        isListLoaded: true
      }
    }

    /**
     * --------------------------------------------------------------------------------------> GET LIST BY DATE
     */

    case EditorActions.GET_LISTS_BY_DATE: {
      return {
        ...state,
        isListLoaded: false
      }
    }

    case EditorActions.GET_LISTS_BY_DATE_SUCCESS: {
      return {
        ...state,
        lists: action.payload,
        isListLoaded: true
      }
    }

    case EditorActions.GET_LISTS_BY_DATE_ERROR: {
      return {
        ...state,
        isListLoaded: true
      }
    }

    /**
     * --------------------------------------------------------------------------------------> ADD TO LIST
     */
    case EditorActions.ADD_TO_LIST: {
      return {
        ...state,
        isListLoaded: true
      }
    }

    case EditorActions.ADD_TO_LIST_SUCCESS: {
      return {
        ...state,
        isListLoaded: false,
        // lists: [action.payload, ...state.lists]
        // lists: [
        //   ...(state.lists.filter( (list) => list.id !== action.payload.id )),
        // ]

        // lists: state.lists.map(
        //   list => list.id === action.payload.id ? {
        //       ...list,
        //       lists: action.payload,
        //   } : list,
        // ),
        // lists: {
        //   ...state.lists,
        //   [action.payload.id] : action.payload
        // }
        lists: action.payload
      }
    }

    /**
     * --------------------------------------------------------------------------------------> REMOVE FROM LIST
     */
    case EditorActions.REMOVE_FROM_LIST: {
      return {
        ...state,
        isListLoaded: true
      }
    }

    case EditorActions.REMOVE_FROM_LIST_SUCCESS: {
      return {
        ...state,
        isListLoaded: false,
        lists: action.payload
      }
    }
    /**
     * --------------------------------------------------------------------------------------> UPDATE LIST ORDER
     */
    case EditorActions.UPDATE_LIST_ORDER: {
      return {
        ...state,
        isListLoaded: true
      }
    }

    case EditorActions.UPDATE_LIST_ORDER_SUCCESS: {
      return {
        ...state,
        isListLoaded: false,
        lists: action.payload
      }
    }
    /**
     * --------------------------------------------------------------------------------------> GET SELECTED LIST
     */
    case EditorActions.GET_SELECTED_LIST_SUCCESS: {
      return {
        ...state,
        selectedList: action.payload.list_id
      }
    }
    /**
     * --------------------------------------------------------------------------------------> UPDATE SELECTED LIST
     */
    case EditorActions.UPDATE_SELECTED_LIST_SUCCESS: {
      return {
        ...state,
        selectedList: action.payload.list_id
      }
    }

    /**
     * --------------------------------------------------------------------------------------> load search
     */
    case EditorActions.LOAD_SEARCH_SUCCESS: {
      return {
        ...state,
        searchNews: action.payload
      }
    }

    case EditorActions.SEARCH_NEWS_ACTION: {
      return {
        ...state,
        searchNews: {
          ...state.searchNews,
          search_status: 'searching',
          pageState: {
            currentPage: action.payload.currentPage,
            perPage: action.payload.perPage,
          }
        }
      };
    }

    case EditorActions.SEARCH_NEWS_ACTION_SUCCESS: {
      return {
        ...state,
        searchNews: {
          ...state.searchNews,
          results: action.payload.data,
          search_status: 'complete',
          pageState: {
            currentPage: action.payload.current_page,
            perPage: Number(action.payload.per_page),
            lastPages: action.payload.last_page,
            totalResults: action.payload.total,
          }
        }
      };
    }


    /**
     * --------------------------------------------------------------------------------------> OTHER ACTIONS
     */
    case EditorActions.CHANGE_REVIEW_NEWS_LOADED: {
      return {
        ...state,
        isLoaded: action.payload.isLoaded
      };
    }

    case EditorActions.LOGOUT_EDITOR_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getReviewNewsState = createFeatureSelector<ReviewNewsState>('ReviewNewsState');
export const getReviewSearchNewsState = createFeatureSelector<SearchNews>('searchNews');

export const getMyNews = createSelector(getReviewNewsState, (state: ReviewNewsState) => state.news);
export const getSearchNews = createSelector(getReviewNewsState, (state) => state);

export const getNewsById = (newsBox, id) => createSelector(getReviewNewsState, (allItems) => {
  if (allItems) {
    return allItems[newsBox].find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});

export const getSearchNewsById = (id) =>
  createSelector(getSearchNews, state => state.searchNews.results.filter(x => x.id === id));
