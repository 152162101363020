import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule} from './demo-material-module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { HttpInterceptorService} from './shared/services/http-interceptor.service';
import { ConfirmDialogComponent } from './shared/component/confirm-dialog/confirm-dialog.component';
import { ViewNewsDialogComponent } from './shared/component/view-news-dialog/view-news-dialog.component';
import { AddTickerNewsDialogComponent } from './shared/component/add-ticker-news-dialog/add-ticker-news-dialog.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { reducers, metaReducers } from './store/app.reducer';
import { UserEffects } from './authentication/store/user.effects';

import { ContextMenuModule } from 'ngx-contextmenu';
// import { SmsComponent } from './sms/sms.component';

import { environment } from '../environments/environment';
/*import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';*/
import {SettingsEffects} from './settings/store/settings.effects';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        AppHeaderComponent,
        SpinnerComponent,
        AppBlankComponent,
        AppSidebarComponent,
        ConfirmDialogComponent,
        ViewNewsDialogComponent,
        AddTickerNewsDialogComponent,
        // SmsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DemoMaterialModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        PerfectScrollbarModule,
        SharedModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' }),
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([UserEffects, SettingsEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 50, logOnly: environment.production }),
        ContextMenuModule.forRoot(),
        /*AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule*/
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

